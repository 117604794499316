import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import NewLogo from "../Images/npai_logo_curves.svg";
import ThreeArrow from "../Images/ThreeArrow.png";
import AboutLogo from "../Images/sidebarlogo/1.svg";
import ProductsLogo from "../Images/sidebarlogo/11.svg";
import servicesLogo from "../Images/sidebarlogo/12.svg";
import Email from "../Images/sidebarlogo/3.svg";
import PressLogo from "../Images/sidebarlogo/4.svg";
import Contact from "../Images/contact.png";
import FAQsLogo from "../Images/sidebarlogo/7.svg";
import ClientLogo from "../Images/sidebarlogo/8.svg";
import BackersLogo from "../Images/sidebarlogo/9.svg";
import LegalLogo from "../Images/sidebarlogo/10.svg";
import Arrow from "../Images/arrow.png";

import { items } from "./data";
import About from "./SideNavBar/About";
import Products from "./SideNavBar/Products.js";
import Services from "./SideNavBar/Services.js";
import Backers from "./SideNavBar/Backers";
import Client from "./SideNavBar/Client.js";
import ContactForm from "./SideNavBar/ContactForm.js";

import Press from "./SideNavBar/PressViewer.js";
import Faq from "./SideNavBar/Faq.js";
import "./Home.css";

import unleash from "../Images/unleash.png";
import unleashMobile from "../Images/Unleash_mobile.png";
import mobileAsset from "../Images/MobileAsset.png";
import Legal from "./SideNavBar/Legal.js";

import LiftImg from "../Images/ProductLogos/Lift.svg";
import BSWAPImg from "../Images/ProductLogos/Bswap.svg";
import MSWAPImg from "../Images/ProductLogos/Mswap.svg";
import QUINImg from "../Images/ProductLogos/QUIN.svg";
import LiftHovorImg from "../Images/ProductLogos/LiftHovor.svg";
import BSWAPHovorImg from "../Images/ProductLogos/BswapHovor.svg";
import MSWAPHovorImg from "../Images/ProductLogos/MswapHovor.svg";
import QUINHovorImg from "../Images/ProductLogos/QuinHovor.svg";
import DownArrowImg from "../Images/DownArrow.svg";

import { Link } from "react-scroll";
import NeuropixelLogo from "../Images/NeuropixelLogo.png";
import ContactUs from "../Pages/NewUi/ContactUs.js";
import HomePage from "../Pages/NewUi/Home";
import ProductsHome from "../Pages/NewUi/Products";
import KeyBenifits from "../Pages/NewUi/KeyBenifits";
import LiftHome from "../Pages/NewUi/LiftHome";
import BSWAPHome from "../Pages/NewUi/BSWAPHome";
import MSWAPHome from "../Pages/NewUi/MSWAPHome";
import QUINHome from "../Pages/NewUi/QUINHome";
import Testimonial from "../Pages/NewUi/Testimonial";
import PricingPage from "../Pages/NewUi/PricingPage";
import Technology from "./NewUi/Technology.js";
import FAQ from "../Pages/NewUi/FAQ";
import Footer from "../Pages/NewUi/Footer";
import ExampleModel from "../Pages/NewUi/ExampleModel";

export default function Home() {
  const [hoveredImage, setHoveredImage] = useState(items[0].input);
  const [hoveredImageOutput, setHoveredImageOutput] = useState(items[0].output);
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const [autoHovering, setAutoHovering] = useState(true);
  const [style, setStyle] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    let interval;
    if (autoHovering) {
      interval = setInterval(() => {
        const nextIndex = (currentItemIndex + 1) % items.length;
        setCurrentItemIndex(nextIndex);
        setHoveredImage(items[nextIndex].input);
        setHoveredImageOutput(items[nextIndex].output);
      }, 1500);
    }
    return () => clearInterval(interval);
  }, [autoHovering, currentItemIndex]);

  useEffect(() => {
    setStyle(
      autoHovering || currentItemIndex !== -1 ? "text-white font-bold" : ""
    );
  }, [autoHovering, currentItemIndex]);

  const handleMouseEnter = () => {
    setAutoHovering(false);
  };

  const handleMouseLeave = () => {
    setAutoHovering(true);
  };

  const handleItemMouseEnter = (index) => {
    setAutoHovering(false);
    setCurrentItemIndex(index);
    setHoveredImage(items[index].input);
    setHoveredImageOutput(items[index].output);
  };

  const handleItemMouseLeave = () => {
    setCurrentItemIndex(-1);
  };

  const handleItemClick = (link) => {
    navigate(link);
  };

  const handleLogoClick = (link) => {
    navigate("/");
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleMenuItemClick = (sectionId, scrollToSection) => {
    setSelectedMenuItem(sectionId);

    scrollToSection();
  };

  const scrollToSection = (sectionId) => {
    document.getElementById(sectionId).scrollIntoView({ behavior: "smooth" });
  };

  const handleCombinedClick = () => {
    toggleModal();
    setTimeout(() => {
      handleMenuItemClick("contact", () => scrollToSection("contact"));
    }, 0);
  };

  const [activeLink, setActiveLink] = useState("home");
  const [isProductsDropdownOpen, setProductsDropdownOpen] = useState(false);

  const toggleProductsDropdown = () => {
    setProductsDropdownOpen(!isProductsDropdownOpen);
  };
  const toggleProductsDropdownClose = () => {
    setProductsDropdownOpen(false);
  };

  const [isHoveredLift, setIsHoveredLift] = useState(false);
  const [isHoveredBSWAP, setIsHoveredBSWAP] = useState(false);
  const [isHoveredMSWAP, setIsHoveredMSWAP] = useState(false);
  const [isHoveredQUIN, setIsHoveredQUIN] = useState(false);

  return (
    <>
      <div
        className="md:w-full md:h-full md:overflow-y-hidden w-0 h-0  invisible md:visible"
        style={{ fontFamily: "DM Sans" }}
      >
        <div
          className="fixed top-0 left-0 md:w-full md:h-[6vh] w-0 h-0 flex justify-between items-center px-[1vw] py-[1vw] text-[#F4F4F5]  text-[0.9vw] z-10"
          style={{
            background:
              "linear-gradient(90deg, #0C2C33 0%, #0CA5C3 100%, #0CB6D8 100%)",
          }}
        >
          <div className="flex items-center cursor-pointer">
            <img src={NeuropixelLogo} alt="Neuropixel.AI" className="w-[9vw]" />
          </div>
          <div className="flex gap-[1.5vw] items-center">
            {/* Products with dropdown */}
            <div className="relative">
              <h1
                className={`cursor-pointer ${
                  activeLink === "products"
                    ? "text-[#FFFFFF] font-semibold"
                    : "text-[#F4F4F5]"
                }`}
                onClick={() => {
                  setActiveLink("products");
                  toggleProductsDropdown();
                }}
              >
                <div className="flex justify-center items-center gap-[0.3vw]">
                  <h1 className="cursor-pointer  text-[0.9vw]">Products</h1>
                  <img
                    src={DownArrowImg}
                    alt="LiftImg"
                    className="w-[0.9vw] items-center pt-[0.2vh]"
                  />
                </div>
              </h1>

              {/* Dropdown menu */}
              {isProductsDropdownOpen && (
                <div className="absolute top-[4.5vh] w-[18vw] flex flex-col gap-[1.5vh] left-[-6.5vw] bg-white px-[2.2vw] py-[4vh] rounded-[0.2vw] shadow-md z-20">
                  <Link
                    to="lift"
                    smooth={true}
                    duration={300}
                    onClick={() => {
                      setProductsDropdownOpen(false);
                      setActiveLink("products");
                    }}
                  >
                    <div
                      className="flex justify-start items-center gap-[0.5vw] text-[#434444]"
                      onMouseEnter={() => setIsHoveredLift(true)}
                      onMouseLeave={() => setIsHoveredLift(false)}
                    >
                      <img
                        src={isHoveredLift ? LiftHovorImg : LiftImg}
                        alt="LiftImg"
                        className="w-[2.5vw] cursor-pointer"
                      />
                      <div className="flex flex-col py-[0.5vh]">
                        <h1
                          className={`cursor-pointer font-semibold text-[1vw] ${
                            isHoveredLift ? "text-[#0CB6D8]" : "text-[#434444]"
                          }`}
                        >
                          LIFT
                        </h1>
                        <h1 className="cursor-pointer text-[0.8vw]">
                          Studio Image to Editorial
                        </h1>
                      </div>
                    </div>
                  </Link>
                  <Link
                    to="bswap"
                    smooth={true}
                    duration={300}
                    onClick={() => {
                      setProductsDropdownOpen(false);
                      setActiveLink("products");
                    }}
                  >
                    <div
                      className="flex justify-start items-center gap-[0.5vw] text-[#434444]"
                      onMouseEnter={() => setIsHoveredBSWAP(true)}
                      onMouseLeave={() => setIsHoveredBSWAP(false)}
                    >
                      <img
                        src={isHoveredBSWAP ? BSWAPHovorImg : BSWAPImg}
                        alt="LiftImg"
                        className="w-[2.5vw] cursor-pointer"
                      />
                      <div className="flex flex-col py-[0.5vh]">
                        <h1
                          className={`cursor-pointer font-semibold text-[1vw] ${
                            isHoveredBSWAP ? "text-[#0CB6D8]" : "text-[#434444]"
                          }`}
                        >
                          BSWAP
                        </h1>
                        <h1 className="cursor-pointer text-[0.8vw]">
                          Background Swap
                        </h1>
                      </div>
                    </div>
                  </Link>
                  <Link
                    to="mswap"
                    smooth={true}
                    duration={300}
                    onClick={() => {
                      setProductsDropdownOpen(false);
                      setActiveLink("products");
                    }}
                  >
                    <div
                      className="flex justify-start items-center gap-[0.5vw] text-[#434444]"
                      onMouseEnter={() => setIsHoveredMSWAP(true)}
                      onMouseLeave={() => setIsHoveredMSWAP(false)}
                    >
                      <img
                        src={isHoveredMSWAP ? MSWAPHovorImg : MSWAPImg}
                        alt="LiftImg"
                        className="w-[2.5vw] cursor-pointer"
                      />
                      <div className="flex flex-col py-[0.5vh]">
                        <h1
                          className={`cursor-pointer font-semibold text-[1vw] ${
                            isHoveredMSWAP ? "text-[#0CB6D8]" : "text-[#434444]"
                          }`}
                        >
                          MSWAP
                        </h1>
                        <h1 className="cursor-pointer text-[0.8vw]">
                          Model Swap
                        </h1>
                      </div>
                    </div>
                  </Link>
                  <Link
                    to="quin"
                    smooth={true}
                    duration={300}
                    onClick={() => {
                      setProductsDropdownOpen(false);
                      setActiveLink("products");
                    }}
                  >
                    <div
                      className="flex justify-start items-center gap-[0.5vw] text-[#434444] "
                      onMouseEnter={() => setIsHoveredQUIN(true)}
                      onMouseLeave={() => setIsHoveredQUIN(false)}
                    >
                      <img
                        src={isHoveredQUIN ? QUINHovorImg : QUINImg}
                        alt="LiftImg"
                        className="w-[2.5vw] cursor-pointer"
                      />
                      <div className="flex flex-col py-[0.5vh]">
                        <h1
                          className={`cursor-pointer font-semibold text-[1vw] ${
                            isHoveredQUIN ? "text-[#0CB6D8]" : "text-[#434444]"
                          }`}
                        >
                          QUIN
                        </h1>
                        <h1 className="cursor-pointer text-[0.8vw]">
                          Mannequin to Model
                        </h1>
                      </div>
                    </div>
                  </Link>
                </div>
              )}
            </div>
            <Link
              to="technology"
              smooth={true}
              duration={300}
              onClick={() => {
                toggleProductsDropdownClose();
                setActiveLink("technology");
              }}
            >
              <h1
                className={`cursor-pointer ${
                  activeLink === "technology"
                    ? "text-[#FFFFFF] font-semibold"
                    : "text-[#F4F4F5]"
                }`}
              >
                Technology
              </h1>
            </Link>
            <Link
              to="pricing"
              smooth={true}
              duration={500}
              onClick={() => {
                toggleProductsDropdownClose();
                setActiveLink("pricing");
              }}
            >
              <h1
                className={`cursor-pointer ${
                  activeLink === "pricing"
                    ? "text-[#FFFFFF] font-semibold"
                    : "text-[#F4F4F5]"
                }`}
              >
                Pricing
              </h1>
            </Link>

            <Link
              to="contact"
              smooth={true}
              duration={500}
              onClick={() => {
                toggleProductsDropdownClose();
                setActiveLink("contact");
              }}
            >
              <h1
                className={`cursor-pointer ${
                  activeLink === "contact"
                    ? "text-[#FFFFFF] font-semibold"
                    : "text-[#F4F4F5]"
                }`}
              >
                Contact
              </h1>
            </Link>
            <button
              className="flex justify-center items-center px-[1vw] py-[0.4vh] border-[0.01vw] rounded-[0.2vw]"
              onClick={() => {
                toggleProductsDropdownClose();
                window.open("https://tool.neuropixel.ai/sign-in");
              }}
            >
              SIGN IN
            </button>
            <button
              className="flex justify-center items-center px-[1vw] py-[0.4vh] border-[0.01vw] rounded-[0.2vw] bg-white text-[#0CB6D8]"
              onClick={() => {
                window.open("https://tool.neuropixel.ai/sign-up");
                toggleProductsDropdownClose();
              }}
            >
              GET STARTED
            </button>
          </div>
        </div>
        <div
          className="overflow-y-auto pt-[6vh]"
          onClick={() => {
            toggleProductsDropdownClose();
          }}
        >
          <div id="products" className="min-h-[10vh]">
            <HomePage />
            <ProductsHome />
            <KeyBenifits />
          </div>
          <div id="lift" className="min-h-[10vh]">
            <LiftHome />
          </div>
          <div id="bswap" className="min-h-[10vh]">
            <BSWAPHome />
          </div>
          <div id="mswap" className="min-h-[10vh]">
            <MSWAPHome />
          </div>
          <div id="quin" className="min-h-[10vh]">
            <QUINHome />
          </div>
          <div id="technology" className="min-h-[10vh]">
            <Technology />
          </div>
          <div id="blog" className="min-h-[10vh]">
            <Testimonial />
          </div>
          <div id="pricing" className="min-h-[10vh]">
            <PricingPage />
            <ExampleModel />
            {/* <FAQ /> */}
          </div>
          <div id="contact" className="min-h-[10vh]">
            <ContactUs />
            <Footer />
          </div>
        </div>
      </div>
      {/* MOBILE SCREEN ONLY */}

      <div
        className={`visible md:hidden w-full h-full md:w-0 md:h-o md:transition-all md:duration-[2s] text-black ${
          isModalOpen ? "md:transform md:translate-x-[55vw]" : ""
        }`}
        style={{
          backgroundColor: isModalOpen ? "bg-[#FFFDF3]" : "",
        }}
      >
        <div
          className={`relative w-full !h-screen flex flex-col md:flex-row justify-start items-start md:text-[1.25rem] lg:text-[1.35rem] xl:text-[2.50rem] overflow-hidden`}
        >
          <div className="relative flex flex-col flex-wrap md:-mx-1 z-[100]">
            <div className="top-0 bg-white left-0 z-10">
              <div className="flex w-[100vw] justify-between p-[1.1vw] pl-[0.5vw] pt-[0.5vw]">
                <div className="flex gap-[2vw]">
                  <div
                    className="flex pl-[0.4vw] pt-[4vw]"
                    onClick={toggleModal}
                    style={{ cursor: "pointer" }}
                  >
                    <img src={ThreeArrow} alt="" className="w-[7vw] h-[5vw]" />
                  </div>
                  <div
                    className="flex pt-[0.5vw]"
                    onClick={handleLogoClick}
                    style={{ cursor: "pointer" }}
                  >
                    <img src={NewLogo} alt="" className="w-[50vw] h-auto " />
                  </div>
                </div>

                <div
                  className="flex justify-end items-end pb-[1vw]"
                  onClick={handleCombinedClick}
                  style={{ cursor: "pointer" }}
                >
                  <img src={Contact} alt="" className="h-auto w-[27vw]" />
                </div>
              </div>
            </div>
            {/* Gradient Background for Mobile */}
            <div className="absolute visible md:invisible w-full h-full gradient-div-home-mobile" />

            {/* Main Content */}
            <div className="relative z-[200]">
              <img
                src={unleashMobile}
                alt=""
                className="left-[-12vw] w-[150vw] "
              />

              <div className="relative flex gap-[3vw] md:gap-0 justify-center items-center md:ml-[2vw] md:pt-0">
                <div className="block pl-[2vw] md:pl-0 sm:pt-[0vw] p-[5vw] md:p-0 md:mt-[22vw] w-[100vw] md:w-[45vw]">
                  <div className="text-[5vw] ml-[0.4vw] md:text-[1.8vw] font-bold text-white">
                    PRODUCTS
                  </div>
                  <div
                    className="w-full z-[100] mt-[0.4vw] text-[0.1vw] md:text-base lg:text-xl xl:text-2xl"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="flex flex-col flex-wrap md:-mx-1 z-[100]">
                      {items.map((item, index) => (
                        <div
                          key={index}
                          className={`m-[0.2vw] md:m-[0.5vw] py-[0.5vw] px-[0.1vw] md:p-[0vw] mb-2 ${
                            currentItemIndex === index ? style : ""
                          }`}
                          onMouseEnter={() => handleItemMouseEnter(index)}
                          onMouseLeave={handleMouseLeave}
                          onTouchStart={(e) => {
                            e.stopPropagation();
                            handleItemMouseEnter(index);
                          }}
                          onTouchEnd={(e) => {
                            e.stopPropagation();
                            handleMouseLeave();
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleItemClick(item.link);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="flex flex-row gap-[1vw] justify-center items-center">
                            <div className="w-[4vw] md:w-[2vw]">
                              {currentItemIndex === index && (
                                <img
                                  src={Arrow}
                                  alt=""
                                  className="w-[6vw] h-[3vw] md:!w-[3vw] md:h-[2vw]"
                                />
                              )}
                            </div>
                            <div className="flex flex-row justify-between w-full">
                              <h1
                                className={`text-white ${
                                  currentItemIndex === index
                                    ? "text-[4vw] md:!text-[1.8vw]"
                                    : "text-[3.5vw] md:text-[1.4vw]"
                                }`}
                              >
                                {item.title}
                              </h1>
                              <h1
                                className={`text-white text-end ${
                                  currentItemIndex === index
                                    ? "text-[2.6vw] md:!text-[1.2vw]"
                                    : "text-[2.5vw] md:text-[0.95vw]"
                                }`}
                              >
                                {item.description}
                              </h1>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {/* <div className="visible md:invisible md:w-0 md:h-0 !w-[15vw] h-[10vw] sm:pb-[35vh] pb-[23vh] ">
                  <img
                    src={mobileAsset}
                    alt=""
                    className="w-[100vw] h-[45vw]"
                  />
                </div> */}
              </div>

              <div className="relative visible md:invisible md:w-0 md:h-0 w-full text-[0.2rem] md:text-base lg:text-xl xl:text-2xl dynamic-bg">
                {hoveredImage && hoveredImageOutput && (
                  <div
                    className="absolute sm:w-[35vw] sm:top-[8vh] w-[40vw] top-[13vh] h-full flex justify-start items-start left-0 z-20 md:hidden"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="block text-center">
                      <div className="w-full mx-auto ">
                        <img
                          src={hoveredImage}
                          alt=""
                          className="w-full h-full shadow-black shadow-2xl"
                        />
                      </div>
                    </div>
                  </div>
                )}

                {hoveredImageOutput && (
                  <div
                    className="h-full flex justify-end items-end right-0 "
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="flex items-end justify-end sm:w-[55vw] w-[63vw] h-[50vh]">
                      <img
                        src={hoveredImageOutput}
                        alt=""
                        className="object-contain shadow-black shadow-2xl"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div
          className={`side-navbar z-[250] fixed top-0 left-0 w-full  md:w-[55vw] h-[100vh] flex transform transition-transform duration-5000  md:text-[0.9vw] p ${
            isModalOpen ? "translate-x-0" : "-translate-x-full"
          }`}
          style={{ fontFamily: "Montserrat, sans-serif" }}
        >
          {/* Left Sidebar */}
          <div
            className="w-[85vw]  md:w-[72vw] max-h-[100vh] scroll-ml-6 bg-[#f0f0f0] overflow-auto scrollbar-custom left-side-navbar"
            style={{ scrollbarWidth: "thin" }}
          >
            {[
              { id: "about", component: <About /> },
              { id: "products", component: <Products /> },
              { id: "services", component: <Services /> },
              { id: "backers", component: <Backers /> },
              { id: "clients", component: <Client /> },
              { id: "contact", component: <ContactForm /> },
              { id: "FAQs", component: <Faq /> },
              { id: "press", component: <Press /> },
              { id: "legal", component: <Legal /> },
            ].map(({ id, component }) => (
              <section key={id} id={id}>
                {component}
              </section>
            ))}
          </div>

          {/* Right Sidebar (Visible on Desktop) */}

          <div
            className="w-[15vw] items-center md:w-[28vw] bg-white md:block right-side-navbar"
            style={{ display: "block" }}
          >
            <div className="p-[1.2vw]  pr-[14vw] md:pr-[0.1vw]">
              {/* Close Button */}
              <button
                onClick={toggleModal}
                className="text-gray-500 hover:text-gray-700 pl-[2vw] md:pl-[10vw]"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-12 w-12"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            {/* Menu Items */}
            <div className="block bg-fixed bg-white">
              {[
                { id: "about", label: "About Us", img: AboutLogo },
                { id: "products", label: "Products", img: ProductsLogo },
                { id: "services", label: "Services", img: servicesLogo },
                { id: "backers", label: "Backers", img: BackersLogo },
                { id: "clients", label: "Clients", img: ClientLogo },
                { id: "contact", label: "Contact", img: Email },
                { id: "FAQs", label: "FAQs", img: FAQsLogo },
                { id: "press", label: "Press", img: PressLogo },
                { id: "legal", label: "Legal", img: LegalLogo },
              ].map(({ id, label, img }) => (
                <div
                  key={id}
                  className={`flex  items-center md:py-[0.4vw] py-[1.5vw] md:pl-[2vw] pl-[3vw] md:transform ${
                    selectedMenuItem === id
                      ? "bg-[#f0f0f0]"
                      : "hover:bg-[#f0f0f0]"
                  }`}
                >
                  <div
                    className={`flex md:flex md:gap-[1vw]  md:items-center  md:transform md:transition-transform md:duration-[1000ms] md:hover:translate-x-2 md: hover:bg-[#f0f0f0] ${
                      selectedMenuItem === id ? "md:translate-x-2" : ""
                    }`}
                    onClick={() =>
                      handleMenuItemClick(id, () => scrollToSection(id))
                    }
                  >
                    <img
                      src={img}
                      alt={label}
                      className="md:h-[2vw] md:w-[2vw] h-[9vw] w-[9vw]"
                    />
                    <span className="invisible md:visible w-0 h-0 md:w-full md:h-full">
                      {label}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
