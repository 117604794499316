import React from "react";

export default function Client() {
  return (
    <div
      className="block pb-[5rem] md:pb-[1rem]"
      style={{ fontFamily: "Montserrat, sans-serif" }}
    >
      <div className="w-full flex items-center justify-left md:pl-[3rem] text-[2rem] bg-[#0CB6D8] p-[1rem] text-white font-bold">
        Clients
      </div>
      <div className="p-[1.5rem] pt-[5rem] md:p-[3.5rem] md:pl-[4.8rem] pb-0 block">
        Following are some of the clients that we have worked with. Some are
        recurring while some were one-off engagements. Gentle disclaimer that
        not all clients have used synthetic humans in their images.
        <div className="mt-[2rem] text-[1.3rem] text-black">
          <div className="mb-[0.5rem] text-[1.7rem] font-semibold">
            INDIA
          </div>
          <div className="ml-[1rem]">
            <div className="mb-[0.5rem]">- Blackberrys</div>
            <div className="mb-[0.5rem]">- Fabindia</div>
            <div className="mb-[0.5rem]">- Indian Terrain</div>
            <div className="mb-[0.5rem]">- Jolene</div>

            <div className="mb-[0.5rem]">- Myntra</div>
            <div className="mb-[0.5rem]">- Lemonade</div>
            <div className="mb-[0.5rem]">- Pantaloons</div>
            <div className="mb-[0.5rem]">- Shades of Earth</div>

            <div className="mb-[0.5rem]">- Soch</div>
            <div className="mb-[0.5rem]">- Taneira</div>

            <div className="mb-[3rem]">- Van Heusen</div>
          </div>
          <div className="mb-[0.5rem] text-[1.7rem] font-semibold">DUBAI</div>
          <div className="ml-[1rem]">
            <div className="mb-[0.5rem]">- Styli (by Landmark)</div>
            <div className="mb-[3rem]">- Pilolo</div>
          </div>
          {/* <div className="mb-[0.5rem] text-[1.7rem] font-semibold">
            MALAYSIA
          </div>
          <div className="ml-[1rem]">
            <div className="mb-[0.5rem]">- Zalora</div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
