import React from "react";

export default function Faq() {
  return (
    <div
      className="block pt-[4rem] pb-[5rem] md:pb-[1rem]"
      style={{ fontFamily: "Montserrat, sans-serif" }}
    >
      <div className="w-full flex items-center justify-left md:pl-[3rem] text-[2rem] bg-[#0CB6D8] p-[1rem] text-white font-bold">
        Frequently Asked Questions
      </div>
      <div className="p-[1.5rem] pt-[5rem] md:p-[3.5rem] md:pl-[4.8rem] pb-0 block">
        <div className="mb-[1rem] text-[1.5rem] md:text-[1.5rem] text-black">
          <i>
            {">"} Can NeuroPixel’s synthetic human’s face resemble a real
            person?
          </i>
        </div>
        <div className="mt-[1rem] mb-[2rem] text-[1.2rem]">
          From a technological standpoint, that probability is the same
          probability as being born with a doppleganger. However, every face we
          create is passed through a third party facial recognition tool that
          scans the internet and checks for similar faces and avoids them.
        </div>

        <div className="mb-[1rem] text-[1.5rem] md:text-[1.5rem] text-black">
          <i>
            {">"} What are the guidelines for sending NeuroPixel a catalog
            image?
          </i>
        </div>
        <div className="mt-[1rem] mb-[2rem] text-[1.2rem]">
          Most conventionally shot catalog images should be absolutely fine. All
          we need is good lighting that portrays your apparel with the right
          colours. We’ll do the rest.
        </div>

        <div className="mb-[1rem] text-[1.5rem] md:text-[1.5rem] text-black">
          <i>{">"} Do you offer a free pilot?</i>
        </div>
        <div className="mt-[1rem] mb-[2rem] text-[1.2rem]">
          Absolutely. We’re happy to process a few images. We’re confident
          you’ll love us once you’ve tried us out!
        </div>

        <div className="mb-[1rem] text-[1.5rem] md:text-[1.5rem] text-black">
          <i>
            {">"} Can NeuroPixel make sure that my synthetic model is used
            exclusively for my brand?
          </i>
        </div>
        <div className="mt-[1rem] mb-[2rem] text-[1.2rem]">
          If that is important to you, absolutely! Today NeuroPIxel does
          generate a ton of a synthetic models so the same model being noticed
          on multiple brands/platforms is a rarity. However, we can ensure that
          ‘seed’ model is exclusively reserved for your brand at a nominal fee.
        </div>

        <div className="mb-[1rem] text-[1.5rem] md:text-[1.5rem] text-black">
          <i>
            {">"} What happens if NeuroPixel sends across an image that you
            don’t like?
          </i>
        </div>
        <div className="mt-[1rem] mb-[2rem] text-[1.2rem]">
          No problem. We’ll re-do it. As long as you give us feedback on why you
          don’t like it, we’re happy to change it out any number of times.
        </div>

        <div className="mb-[1rem] text-[1.5rem] md:text-[1.5rem] text-black">
          <i>{">"} Are NeuroPixel images live anywhere at the moment?</i>
        </div>
        <div className="mt-[1rem] mb-[2rem] text-[1.2rem]">
          Yes they are. However it’s at the discretion of the brand to let
          people know that they are AI model images. So we can’t share links
          here unfortunately but do get in touch and we’ll be able to solve any
          apprehensions.
        </div>

        <div className="mb-[1rem] text-[1.5rem] md:text-[1.5rem] text-black">
          <i>
            {">"} Are there any restrictions on the type of apparel NeuroPixel
            can process?
          </i>
        </div>
        <div className="mt-[1rem] mb-[2rem] text-[1.2rem]">
          With translucent or netted fabrics, there could be an issue when you
          change the model ethnicity or skin-colour. Except for that, we should
          be all clear.
        </div>

        <div className="mb-[1rem] text-[1.5rem] md:text-[1.5rem] text-black">
          <i>{">"} Can NeuroPixel change the pose of the input model?</i>
        </div>
        <div className="mt-[1rem] mb-[2rem] text-[1.2rem]">
          We prefer not to do this as each fabric is different and the drape and
          fall could change.
        </div>

        <div className="mb-[1rem] text-[1.5rem] md:text-[1.5rem] text-black">
          <i>
            {">"} How is this different from other AI image generators or an
            Image Editing software’s ‘Gen-AI Fill’ function?
          </i>
        </div>
        <div className="mt-[1rem] mb-[2rem] text-[1.2rem]">
          All diffusion based image generators typically suffer from some form
          of hallucinations. They end up slightly changing your garment’s
          pattern/silhouette, thereby making the process not conducive for
          fashion brands where the garment needs to be replicated exactly as is.
          With regard to Gen-AI fill tools, the lighting doesn’t end up coming
          out right; it ends up looking like a cookie-cutter pasted image. With
          NeuroPixel, the lighting of the source image is woven into the
          generation process itself in a manner that makes the images much more
          real. When it comes to marketing/editorial images, magic lies in that
          last bit of finesse. That’s where NeuroPixel stands out.
        </div>
      </div>
    </div>
  );
}
