import React from "react";
import AIImg from "../../Images/EyeImg.png";

export default function ExampleModel() {
  return (
    <div
      className="flex justify-center items-center w-[100vw]"
      style={{
        background: "linear-gradient(90deg, #F7CEE7 0%, #D9F8FA 100%)",
      }}
    >
      <div className="flex flex-col w-[50vw] justify-start items-start px-[8vw] text-[#303030]">
        <div className="text-[3vw] font-semibold leading-[4vh]">Are AI generated models</div>
        <div className="text-[3vw] font-semibold">safe to use?</div>
        <div className="text-[1vw] text-[#434444] pt-[1vh]">
          Every identity we create is passed through an online facial
          recognition tool to ensure we don't accidentally generate a celebrity,
          a famous model, or anybody with multiple photos on the internet.
        </div>
      </div>
      <div className="w-[50vw]">
        <img src={AIImg} alt="AIImg" className="w-[50vw] object-contain" />
      </div>
    </div>
  );
}
