import React from "react";
import NeuropixelLogo from "../../Images/NeuropixelLogo.png";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col justify-center items-center w-[100vw]">
      <div className="flex justify-start gap-[30vw] items-start h-[40vh] bg-[#303030] text-[#FFFFFF] px-[3vw] py-[8vh] border-b-[0.2vh] border-[#434444] w-[100vw]">
        <div className="flex flex-col justify-start items-start w-[20vw] gap-[2vh]">
          <img
            src={NeuropixelLogo}
            alt="NeuropixelLogo"
            className="w-[9vw] object-contain"
          />
          <span className="">
            No. 2908, Urban Vault, 688, 22nd Cross Rd, 2nd Sector, HSR Layout,
            Bengaluru, Karnataka 560102
          </span>
        </div>

        <div className="flex gap-[1vw] justify-start items-start">
          <div className="flex flex-col justify-start items-start w-[18vw] gap-[2vh]">
            <span className="text-[#FFFFFF] text-[1.2vw]">Product Samples</span>
            <div className="flex flex-col justify-start items-start gap-[0.5vh]">
              <span
                className="cursor-pointer hover:underline"
                onClick={() => {
                  navigate("/Lift");
                }}
              >
                LIFT
              </span>
              <span
                className="cursor-pointer hover:underline"
                onClick={() => {
                  navigate("/MSWAP");
                }}
              >
                MSWAP
              </span>
              <span
                className="cursor-pointer hover:underline"
                onClick={() => {
                  navigate("/BSWAP");
                }}
              >
                BSWAP
              </span>
              <span
                className="cursor-pointer hover:underline"
                onClick={() => {
                  navigate("/Quin");
                }}
              >
                QUIN
              </span>
            </div>
          </div>
          {/* <div className="flex flex-col justify-start items-start w-[18vw] gap-[2vh]">
            <span className="text-[#FFFFFF] text-[1.2vw]">Company</span>
            <div className="flex flex-col justify-start items-start gap-[0.5vh]">
              <span>About Us</span>
              <span>Pricing</span>
              <span>Press</span>
              <span>Blog</span>
              <span>Help</span>
              <span>Product Roadmap</span>
              <span>Product Updates</span>
            </div>
          </div> */}

          <div className="flex flex-col justify-start items-start w-[18vw] gap-[2vh]">
            <span className="text-[#FFFFFF] text-[1.2vw]">Legal</span>
            <div className="flex flex-col justify-start items-start gap-[0.5vh]">
              <span
                className=" cursor-pointer hover:underline"
                onClick={() => {
                  navigate("/privacy-policy");
                }}
              >
                Privacy Policy
              </span>
              <span
                className=" cursor-pointer hover:underline"
                onClick={() => {
                  navigate("/Refund-policy");
                }}
              >
                Refund Policy
              </span>
              <span
                className=" cursor-pointer hover:underline"
                onClick={() => {
                  navigate("/terms-of-use");
                }}
              >
                Terms of Use
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center items-center text-[0.9vw] w-full text-[#FAFAFA] bg-[#303030] py-[2vh]">
        © 2024 NeuroPixel.AI All Rights Reserved
      </div>
    </div>
  );
}
