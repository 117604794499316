import React from "react";
import { useNavigate } from "react-router-dom";

export default function Services() {
  const navigate = useNavigate();

  return (
    <div
      className="block pb-[5rem] md:pb-[1rem]"
      style={{ fontFamily: "Montserrat, sans-serif" }}
    >
      <div className="w-full md:w-[3vw8] flex items-center justify-left md:pl-[3rem] text-[2rem] bg-[#0CB6D8] p-[1rem] text-white font-bold">
        Services
      </div>
      <div className="p-[1.5rem] pt-[5rem] md:p-[3.5rem] md:pl-[4.8rem] pb-0 block">
        <div className="mt-[2rem] mb-[2rem] text-[1.2rem]  md:w-[100%]">
          <div className="p-[1.5rem]  block bg-white ">
            <h2 className="text-[16px] text-gray-700 mb-[1rem]">BLITZ</h2>
            <h5 className="text-[10px] text-gray-700 mb-[1rem]">
              EDITORIALS WITH ENVIRONMENT INTERACTION
            </h5>
            <ul style={{ listStyleType: "disc" }} className="pl-[4rem]">
              <li>
                Designed for premium fashion brands to reduce costs while
                creating stunning marketing visuals for social media ads and
                creatives.
              </li>
              <li>
                {" "}
                Similar to A5_LIFT in the sense that the human interacts with
                the environment here which is relatively more complex from a
                generation standpoint.
              </li>
            </ul>
            <div
              className="flex justify-end items-end pt-[1rem] md:pt-0"
              onClick={() => {
                navigate("/Blitz");
              }}
              style={{ cursor: "pointer" }}
            >
              <u className="text-black text-[1.2rem] hover:text-blue-800">
                View Samples
              </u>
            </div>
          </div>
        </div>

        <div className="mt-[2rem] mb-[2rem] text-[1.2rem]  md:w-[100%]">
          <div className="p-[1.5rem]  block bg-white ">
            <div className="text-[16px] text-gray-700 mb-[1rem]">
              VIRTUAL INFLUENCER
            </div>
            <h5 className="text-[10px] text-gray-700 mb-[1rem]">
              YOUR VIRTUAL BRAND AMBASSADOR
            </h5>
            <ul style={{ listStyleType: "disc" }} className="pl-[4rem]">
              <li>
                Tailored for agencies, premium brands and platforms to craft
                fictional online personas as brand ambassadors.
              </li>
              <li>
                We function as a technical partner, collaborating closely with
                your creative/marketing team.
              </li>
            </ul>
            <div
              className="flex justify-end items-end pt-[1rem] md:pt-0"
              onClick={() => {
                navigate("/Influen");
              }}
              style={{ cursor: "pointer" }}
            >
              <u className="text-black text-[1.2rem] hover:text-blue-800">
                View Samples
              </u>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
