import React from "react";
import Gen1 from "../../Images/GENS/1.jpg";
import Gen2 from "../../Images/GENS/2.jpg";
import Gen3 from "../../Images/GENS/3.jpg";
import Gen4 from "../../Images/GENS/4.jpg";
import Gen5 from "../../Images/GENS/5.jpg";
import Gen6 from "../../Images/GENS/6.jpg";
import Gen7 from "../../Images/GENS/8.jpg";
import Gen8 from "../../Images/GENS/15.jpg";
import Gen9 from "../../Images/GENS/16.jpg";
import Gen10 from "../../Images/GENS/24.jpg";
import Gen11 from "../../Images/GENS/26.jpg";
import Gen12 from "../../Images/GENS/27.jpg";

const VerticalCarousel = () => {
  const Vertical1 = [Gen1, Gen2, Gen3, Gen4];
  const Vertical2 = [Gen5, Gen6, Gen7, Gen8];
  const Vertical3 = [Gen9, Gen10, Gen11, Gen12];

  return (
    <div className="flex w-[50vw] h-full gap-[1vw] overflow-hidden">
      <div className="group">
        <div className="flex flex-col animate-scrollY group-hover:[animation-play-state:paused]">
          {Vertical1.concat(Vertical1).map((img, index) => (
            <div key={index} className="grid">
              <img
                src={img}
                alt=""
                className="object-contain w-full h-full py-[0.5vw]"
              />
            </div>
          ))}
        </div>
      </div>
      <div className="group">
        <div className="flex flex-col animate-scrollY2 group-hover:[animation-play-state:paused]">
          {Vertical2.concat(Vertical2).map((img, index) => (
            <div key={index} className="grid">
              <img
                src={img}
                alt=""
                className="object-contain w-full h-full py-[0.5vw]"
              />
            </div>
          ))}
        </div>
      </div>
      <div className="group">
        <div className="flex flex-col animate-scrollY group-hover:[animation-play-state:paused]">
          {Vertical3.concat(Vertical3).map((img, index) => (
            <div key={index} className="grid">
              <img
                src={img}
                alt=""
                className="object-contain w-full h-full py-[0.5vw]"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VerticalCarousel;
