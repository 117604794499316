import React from "react";
import Benifit from "../../Images/KeyBenifits/Benefit.png";
import Benifit1 from "../../Images/KeyBenifits/Benefit (1).png";
import Benifit2 from "../../Images/KeyBenifits/Benefit (2).png";
import Benifit3 from "../../Images/KeyBenifits/Benefit (3).png";
import Benifit4 from "../../Images/KeyBenifits/Benefit (4).png";
import Benifit5 from "../../Images/KeyBenifits/Benefit (5).png";

export default function KeyBenifits() {
  // Array of benefit images
  const benefits = [Benifit, Benifit1, Benifit2, Benifit3, Benifit4, Benifit5];

  return (
    <div
      className="flex flex-col justify-center items-center gap-[5vh] pt-[10vh] pb-[15vh]"
      style={{
        background: "linear-gradient(90deg, #0CB6D81A  0%,  #1ACC7629 100%)",
      }}
    >
      <div className="text-[#303030] text-[2vw] font-[700]">Key Benefits</div>
      <div className="flex justify-center items-center gap-[3vw]">
        {benefits.map((benefit, index) => (
          <div
            key={index}
            className="relative p-[2px] rounded-lg" // Adds space for the border
            style={{
              background:
                "linear-gradient(90deg, #0CB6D8D9 0%, #1ACC76D9 100%)", // Gradient background for border
            }}
          >
            <div className="bg-white px-[1vw] py-[2vh] rounded-lg">
              <img
                src={benefit}
                alt={`Benefit-${index}`}
                className="w-[10vw] object-contain"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
