import React from "react";
import { useNavigate } from "react-router-dom";

export default function Products() {
  const navigate = useNavigate();

  return (
    <div
      className="block pb-[5rem] md:pb-[1rem]"
      style={{ fontFamily: "Montserrat, sans-serif" }}
    >
      <div className="w-full md:w-[3vw8] flex items-center justify-left md:pl-[3rem] text-[2rem] bg-[#0CB6D8] p-[1rem] text-white font-bold">
        Products
      </div>
      <div className="p-[1.5rem] pt-[5rem] md:p-[3.5rem] md:pl-[4.8rem] pb-0 block">
        <div className="mt-[2rem] mb-[20px] text-[1.2rem]">
          Our same core technology can be applied for a variety of use-cases For
          visual examples, please do check out the individual solution pages.
        </div>

        <div className="mt-[2rem] mb-[2rem] text-[1.2rem]  md:w-[100%]">
          <div className="p-[1.5rem]  block bg-white ">
            <div className="text-[16px] text-gray-700 mb-[1rem]">LIFT</div>
            <h5 className="text-[10px] text-gray-700 mb-[1rem]">
              MODELSWAP + ENVIRONMENT + LIGHTING
            </h5>
            <ul style={{ listStyleType: "disc" }} className="pl-[4rem]">
              <li>
                Ideal for mass and premium fashion brands looking to boost
                on-platform conversion as well as engagement on digital
                marketing ads.
              </li>
              <li>
                {" "}
                Our output typically replaced the primary ‘look-shot’ image with
                an AI-generated image where we swap the model, the environment,
                and adapt the lighting.
              </li>
            </ul>
            <div
              className="flex justify-end items-end pt-[1rem] md:pt-0"
              onClick={() => {
                navigate("/Lift");
              }}
              style={{ cursor: "pointer" }}
            >
              <u className="text-black text-[1.2rem] hover:text-blue-800">
                View Samples
              </u>
            </div>
          </div>
        </div>

        <div className="mt-[2rem] mb-[2rem] text-[1.2rem]  md:w-[100%]">
          <div className="p-[1.5rem]  block bg-white ">
            <div className="text-[16px] text-gray-700 mb-[1rem]">MSWAP</div>
            <h5 className="text-[10px] text-gray-700 mb-[1rem]">
              MODELSWAP WITH LIGHTING ADAPTATION
            </h5>

            <ul style={{ listStyleType: "disc" }} className="pl-[4rem]">
              <li>
                Designed for mass and premium brands to enhance diversity in
                catalogue images.
              </li>
              <li>
                Replaces models in all catalogue images with AI models of any
                ethnicity, ensuring consistency across images.
              </li>
            </ul>
            <div
              className="flex justify-end items-end pt-[1rem] md:pt-0"
              onClick={() => {
                navigate("/MSWAP");
              }}
              style={{ cursor: "pointer" }}
            >
              <u className="text-black text-[1.2rem] hover:text-blue-800">
                View Samples
              </u>
            </div>
          </div>
        </div>

        <div className="mt-[2rem] mb-[2rem] text-[1.2rem]  md:w-[100%]">
          <div className="p-[1.5rem]  block bg-white ">
            <div className="text-[16px] text-gray-700 mb-[1rem]">BSWAP</div>
            <h5 className="text-[10px] text-gray-700 mb-[1rem]">
              {" "}
              BACKGROUND SWAP WITH LIGHTING ADAPTATION
            </h5>
            <ul style={{ listStyleType: "disc" }} className="pl-[4rem]">
              <li>
                Ideal for value brands seeking cost-effective, immersive imagery
                using the provided model.
              </li>
              <li>
                Quality is slightly lower than A5_LIFT as immersion is better
                when synthetic humans are generated with the environment.
              </li>
            </ul>
            <div
              className="flex justify-end items-end pt-[1rem] md:pt-0"
              onClick={() => {
                navigate("/BSWAP");
              }}
              style={{ cursor: "pointer" }}
            >
              <u className="text-black text-[1.2rem] hover:text-blue-800">
                View Samples
              </u>
            </div>
          </div>
        </div>

        <div className="mt-[2rem] mb-[2rem] text-[1.2rem]  md:w-[100%]">
          <div className="p-[1.5rem]  block bg-white ">
            <div className="text-[16px] text-gray-700 mb-[1rem]">QUIN</div>
            <h5 className="text-[10px] text-gray-700 mb-[1rem]">
              MANNEQUIN TO MODEL
            </h5>
            <ul style={{ listStyleType: "disc" }} className="pl-[4rem]">
              <li>
                Designed for value brands and manufacturers to upgrade mannequin
                images to AI model images.
              </li>
              <li>
                We replace the mannequin with an AI model of any ethnicity,
                maintaining the same poses.
              </li>
            </ul>
            <div
              className="flex justify-end items-end pt-[1rem] md:pt-0"
              onClick={() => {
                navigate("/Quin");
              }}
              style={{ cursor: "pointer" }}
            >
              <u className="text-black text-[1.2rem] hover:text-blue-800">
                View Samples
              </u>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
