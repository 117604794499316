import input1 from "../Images/Input Images/blitz.jpg";
import input2 from "../Images/Input Images/lift.jpg";
import input3 from "../Images/Input Images/mswap.jpg";
import input4 from "../Images/Input Images/bswap.jpg";
import input5 from "../Images/Input Images/quin.jpg";
import input6 from "../Images/Input Images/vinfluen.jpg";


import output1 from "../Images/Output Images/Blitz.jpg";
import output2 from "../Images/Output Images/lift.jpg";
import output3 from "../Images/Output Images/MSWAP.jpg";
import output4 from "../Images/Output Images/Bswap.jpg";
import output5 from "../Images/Output Images/Quin.jpg";
import output6 from "../Images/Output Images/VInfluencer.jpg";


export const items = [
  
  {
    title: "LIFT",
    link: "/Lift",
    description: "EDITORIALS ACROSS CATALOG (STANDING)",
    input: input2,
    output: output2,
  },
  {
    title: "MSWAP",
    link: "/MSWAP",
    description: "SWAP OUT THE MODEL",
    input: input3,
    output: output3,
  },
  {
    title: "BSWAP",
    link: "/BSWAP",
    description: "SWAP OUT THE BACKGROUND",
    input: input4,
    output: output4,
  },
  {
    title: "QUIN",
    link: "/Quin",
    description: "MANNEQUIN TO MODEL",
    input: input5,
    output: output5,
  },
 
];
