import React, { useState } from "react";
import QUINImg from "../../Images/ProductLogos/QUIN.svg";
import QUINHeading from "../../Images/QUIN.png";

import TickImg from "../../Images/Tick.svg";
import RightArrow from "../../Images/RightArrow.svg";
import AIImg from "../../Images/AIImg.svg";
import HandImg from "../../Images/HandImg.svg";
import { useNavigate } from "react-router-dom";

import Source1 from "../../Images/QUINAllAssests/Source/Brown Mannequin Front.jpg";
import Source2 from "../../Images/QUINAllAssests/Source/Brown Mannequin Back.jpg";
import Source3 from "../../Images/QUINAllAssests/Source/Mannequin Front.jpg";
import Source4 from "../../Images/QUINAllAssests/Source/Mannequin Back.jpg";

import Model1 from "../../Images/QUINAllAssests/Model/Asian.jpg";
import Model2 from "../../Images/QUINAllAssests/Model/Blonde.jpg";

import OutPut1 from "../../Images/QUINAllAssests/QUINGens/Model 1/04..jpg";
import OutPut2 from "../../Images/QUINAllAssests/QUINGens/Model 1/02..jpg";
import OutPut3 from "../../Images/QUINAllAssests/QUINGens/Model 1/03..jpg";
import OutPut4 from "../../Images/QUINAllAssests/QUINGens/Model 1/01..jpg";
import OutPut5 from "../../Images/QUINAllAssests/QUINGens/Model 2/04..jpg";
import OutPut6 from "../../Images/QUINAllAssests/QUINGens/Model 2/02..jpg";
import OutPut7 from "../../Images/QUINAllAssests/QUINGens/Model 2/03..jpg";
import OutPut8 from "../../Images/QUINAllAssests/QUINGens/Model 2/01..jpg";

export default function QUINHome() {
  const navigate = useNavigate();
  const row1 = [Source3, Source4, Source1, Source2];
  const row3 = [Model1, Model2];
  const Output = [
    OutPut3,
    OutPut7,
    OutPut1,
    OutPut5,
    OutPut4,
    OutPut8,
    OutPut2,
    OutPut6,
  ];

  const [selectedRow1Index, setSelectedRow1Index] = useState(0); // For row1
  const [selectedRow3Index, setSelectedRow3Index] = useState(0); // For row3

  // Function to calculate the final image index using 12a + 4b + c
  const calculateImageIndex = () => {
    return row3.length * selectedRow1Index + selectedRow3Index;
  };

  const displayImageIndex = calculateImageIndex();

  return (
    <div className="flex justify-center items-center gap-[8vw] bg-[#FFEFEF] py-[10vh] px-[4vw]">
      <div className="flex justify-center items-center">
        {/* <p className="text-[1.2vw]">
          Selected Image Index: {displayImageIndex}
        </p> */}
        <img
          src={Output[displayImageIndex]}
          alt="Selected Display"
          className="w-[38vw] object-contain"
        />
      </div>
      <div className="flex flex-col gap-[1.5vh] justify-start items-start">
        <div className="flex flex-col gap-[0.8vh] justify-start items-start">
          <div className="flex justify-start items-center gap-[2vw]">
            <img
              src={QUINImg}
              alt="QUINImg"
              className="w-[5vw] h-[12vh] object-contain"
            />

            <img
              src={QUINHeading}
              alt="QUINHeading"
              className="w-[12vw] h-[12vh] object-contain"
            />
          </div>
          <div className="flex flex-col justify-start items-start text-[#434444] text-[1.2vw] font-[600]">
            <span className=" leading-[2vh]">
              Shoot on a mannequin and substitute it out to a photorealistic
            </span>
            <span>synthetic human of any ethnicity.</span>
          </div>
        </div>

        <div className="flex flex-col justify-start items-start text-[1vw] gap-[0.5vh]">
          <div className="flex justify-start items-start text-[#303030] text-[1vw] font-semibold">
            Made for
          </div>
          <div className="flex justify-between items-center gap-[4.5vw] text-[#434444]">
            <div className="flex justify-center items-center gap-[0.5vw]">
              <img src={TickImg} alt="TickImg" className="w-[1vw] h-[2vh]" />
              <span>Manufacturers</span>
            </div>
            <div className="flex flex-col items-start justify-start  gap-[0.5vh] text-[#434444]">
              <div className="flex justify-center items-center gap-[0.5vw]">
                <img src={TickImg} alt="TickImg" className="w-[1vw] h-[2vh]" />
                <span>Value Brands</span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-[1vh]">
          <div className="flex flex-row gap-[2vh] overflow-hidden">
            {/* Row 1 */}
            <div className="flex flex-col justify-center items-center gap-[0.5vh]">
              <div className="flex items-center justify-start text-[1vw] text-[#303030] w-full font-semibold">
                Source
              </div>
              <div className="flex justify-center items-center gap-[1vw]">
                {row1.map((img, index) => (
                  <div
                    key={index}
                    className={`relative cursor-pointer border-[0.1vw] ${
                      selectedRow1Index === index
                        ? "border-transparent bg-gradient-to-r from-[#0CB6D8] to-[#1ACC76] p-[0.1vw] rounded border-[0.1vw]"
                        : "border-[0.1vw] border-transparent"
                    }`}
                    onClick={() => setSelectedRow1Index(index)}
                  >
                    <img
                      src={img}
                      alt={`Row 1 Image ${index + 1}`}
                      className="w-[6vw] object-contain"
                    />
                    <div className="absolute flex items-center justify-center  px-[0.5vw] py-[0.2vh] bottom-0 right-0 bg-[#0CB6D8] rounded-[50vw] text-[0.7vw] text-white  mx-[0.2vw] mb-[0.2vh]">
                      Real
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Row 3 */}
          <div className="flex flex-col justify-start items-start gap-[0.5vh]">
            <div className="flex items-center justify-start text-[1vw] text-[#303030] w-ful font-semibold">
              Model
            </div>
            <div className="flex justify-start items-center gap-[1vw]">
              {row3.map((img, index) => (
                <div
                  key={index}
                  className={`cursor-pointer border-[0.1vw] relative ${
                    selectedRow3Index === index
                      ? "border-transparent bg-gradient-to-r from-[#0CB6D8] to-[#1ACC76] p-[0.1vw] rounded border-[0.1vw]"
                      : "border-[0.1vw] border-transparent"
                  }`}
                  onClick={() => setSelectedRow3Index(index)}
                >
                  <img
                    src={img}
                    alt={`Row 2 Image ${index + 1}`}
                    className="w-[6vw] object-contain"
                  />
                  <div className="absolute flex items-center justify-center  px-[0.5vw] py-[0.2vh] bottom-0 right-0 bg-[#0CB6D8] rounded-[50vw] text-[0.7vw] text-white  mx-[0.2vw] mb-[0.2vh]">
                    AI
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex justify-start items-center gap-[1vw] pt-[1vh] pb-[2vh]">
          <button
            className="flex justify-center items-center px-[2vw] py-[1.1vh]  border-[0.1vw] rounded-[0.2vw] text-white bg-[#0CB6D8] text-[1vw] hover:bg-[#10A9C7]"
            onClick={() => {
              window.open("https://tool.neuropixel.ai/sign-up");
            }}
          >
            TRY IT NOW
          </button>
          <button className="flex justify-center items-center px-[1.4vw] py-[1.1vh] border-[0.01vw] rounded-[0.2vw] bg-white text-[#0CB6D8] text-[1vw] border-[#0CB6D8] hover:bg-[#F2F4F7]">
            <div
              className="flex justify-center items-center gap-[0.5vw]"
              onClick={() => {
                navigate("/Quin");
              }}
            >
              <span>VIEW SAMPLES</span>
              {/* <img
                src={RightArrow}
                alt="Lift Image"
                className="w-[1vw] h-[2vh] object-contain"
              /> */}
            </div>
          </button>
        </div>

        <div className="flex flex-col gap-[1.5vh]">
          <div className="flex justify-center items-center gap-[1vw] bg-[#B5FFDC] px-[1vw] py-[0.7vh] rounded-[0.2vw] w-[40vw]">
            <img
              src={AIImg}
              alt="AIImg"
              className="w-[3vw] h-[6vh] object-contain"
            />
            <div className="flex justify-start items-center text-[#434444] text-[0.9vw]">
              Our Hollow Fusion engine will retain the apparel exactly as is,
              preserving the drape and fall of the fabric, and give you a higher
              converting image.
            </div>
          </div>
          <div className="flex justify-center items-center gap-[1vw] bg-[#B5EDFF] px-[1vw] py-[0.7vh] rounded-[0.2vw] w-[40vw] ">
            <img
              src={HandImg}
              alt="HandImg"
              className="w-[2.5vw] h-[6vh] object-contain"
            />
            <div className="flex justify-start items-center text-[#434444] text-[0.9vw] ">
              With more relatable imagery, delivered at an ultra-low cost, your
              customers are much more likely to purchase without any added
              effort.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
