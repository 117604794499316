import React, { useState } from "react";
import axios from "axios";
import { CountryCode } from "../SideNavBar/country-code";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    companyName: "",
    email: "",
    countryCode: "+91",
    phoneNumber: "",
    service: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [isPopupVisible, setPopupVisible] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName)
      newErrors.firstName = "Please enter your first name.";
    if (!formData.companyName)
      newErrors.companyName = "Please enter your company name.";
    if (!formData.email) {
      newErrors.email = "Please enter your email.";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = "Please enter a valid email address.";
    }
    if (!formData.phoneNumber)
      newErrors.phoneNumber = "Please enter your phone number.";
    if (!formData.service) newErrors.service = "Please select a product.";
    if (!formData.countryCode)
      newErrors.countryCode = "Please select a country code.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    if (validateForm()) {
      setLoading(true);
      axios
      .post("https://neuropixel-website-master.onrender.com/send-email", formData)
        .then((response) => {
          setPopupVisible(true);
          setFormData({
            firstName: "",
            companyName: "",
            email: "",
            countryCode: "+91",
            phoneNumber: "",
            service: "",
            message: "",
          });
          setLoading(false);
        })
        .catch((error) => {
          console.error("API error:", error);
          setLoading(false);
        });
    }
  };

  const closePopup = () => {
    setPopupVisible(false);
  };

  return (
    <div className="relative">
      {loading && (
        <div className="fixed inset-0 flex justify-center items-center bg-white bg-opacity-75 z-50">
          <div className="flex flex-col gap-[2vw] justify-center items-center h-screen">
            <div className="border-t-4 border-blue-500 border-solid rounded-full w-[10vw] h-[10vw] md:w-[8vw] md:h-[8vw] animate-spin"></div>
            <div
              className="text-gray-900 text-[4vw] md:text-[2vw] texfont-semibold"
              style={{ fontFamily: "popins" }}
            >
              Sending .......
            </div>
          </div>
        </div>
      )}
      <div
        className="block pb-[5rem] md:pb-[1rem] text-black"
        style={{ fontFamily: "Montserrat, sans-serif" }}
        id="contact"
      >
        <div className="w-full flex items-center justify-left md:pl-[3rem] text-[2rem] bg-[#0CB6D8] p-[1rem] text-white font-bold">
          Contact Us
        </div>

        <div className="flex p-[1.5rem]  pb-0 pl-[3rem] flex-col md:flex-row md:gap-[10rem] mb-[4rem] md:mr-[10rem]">
          <div className="block mt-[2rem]">
            <div className="text-[2rem] mb-[2rem]">Address</div>
            <div className="text-[1.2rem]">
              No. 2908, Club Road, Urban Vault, 688, 22nd Cross Rd, 2nd Sector,
              Parangi Palaya, Sector 2, HSR Layout, Bengaluru, Karnataka 560102
            </div>
          </div>
          <div className="block mt-[2rem]">
            <div className="text-[1.5rem] mb-[2rem]">Follow Us</div>
            <div className="text-[1.2rem]">
              <a
                href="https://www.facebook.com/NeuroPixel/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="flex gap-[5%] items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="14"
                    height="20"
                  >
                    <path d="M22.676 0H1.325C.593 0 0 .593 0 1.326v21.348C0 23.407.593 24 1.325 24H12.82v-9.294H9.692v-3.622h3.128V8.413c0-3.1 1.894-4.788 4.66-4.788 1.325 0 2.462.099 2.793.143v3.24h-1.917c-1.506 0-1.797.715-1.797 1.764v2.314h3.592l-.468 3.622h-3.124V24h6.128c.73 0 1.324-.593 1.324-1.326V1.326C24 .593 23.407 0 22.676 0" />
                  </svg>
                  <span>Facebook</span>
                </div>
              </a>
            </div>
            <div className="text-[1.2rem]">
              <a
                href="https://www.linkedin.com/company/neuropixel-ai/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="flex gap-[5%] items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="10"
                    height="24"
                    fill="blue"
                  >
                    <path d="M22.23 0H1.77C.792 0 0 .775 0 1.726v20.546C0 23.225.792 24 1.77 24h20.46C23.208 24 24 23.225 24 22.272V1.726C24 .775 23.208 0 22.23 0zM7.069 20.452H3.56V9h3.509v11.452zM5.314 7.506c-1.12 0-2.03-.926-2.03-2.067 0-1.141.91-2.067 2.03-2.067 1.119 0 2.03.926 2.03 2.067 0 1.141-.911 2.067-2.03 2.067zM20.452 20.452h-3.509v-5.797c0-1.383-.026-3.162-1.925-3.162-1.928 0-2.223 1.505-2.223 3.057v5.902h-3.509V9h3.367v1.561h.048c.469-.885 1.614-1.818 3.326-1.818 3.556 0 4.211 2.341 4.211 5.384v6.324h-.001z" />
                  </svg>

                  <span>LinkedIn</span>
                </div>
              </a>
            </div>
            <div className="text-[1.2rem]">
              <a
                href="https://twitter.com/NeuroPixelAI"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="flex gap-[5%] items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="24"
                    fill="currentColor"
                    class="bi bi-twitter-x"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                  </svg>

                  <span className="">X.com</span>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div
          className="font-bold  p-[1.5rem]  pb-0 pl-[3rem] text-[1.8rem] mb-[2rem]"
          style={{ fontFamily: "Poppins, sans-serif" }}
        >
          Contact Form
        </div>

        <div className="mt-[2rem] p-[1.5rem] pl-[3rem] text-[1.3rem]">
          <div className="mt-[5rem]">
            <div className="mb-[1rem]">
              <label htmlFor="firstName">Name</label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                className="border p-[0.5rem] w-full"
                value={formData.firstName}
                onChange={handleInputChange}
              />
              {errors.firstName && (
                <div className="text-red-500">{errors.firstName}</div>
              )}
            </div>
            <div className="mb-[1rem]">
              <label htmlFor="companyName">Company</label>
              <input
                type="text"
                id="companyName"
                name="companyName"
                className="border p-[0.5rem] w-full"
                value={formData.companyName}
                onChange={handleInputChange}
              />
              {errors.companyName && (
                <div className="text-red-500">{errors.companyName}</div>
              )}
            </div>
            <div className="mb-[1rem]">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                className="border p-[0.5rem] w-full"
                value={formData.email}
                onChange={handleInputChange}
              />
              {errors.email && (
                <div className="text-red-500">{errors.email}</div>
              )}
            </div>
            <div className="flex gap-[2%]">
              <div className="mb-[1rem] w-[40%]">
                <label htmlFor="countryCode">Country Code</label>
                <select
                  id="countryCode"
                  name="countryCode"
                  className="border p-[0.5rem] w-full"
                  value={formData.countryCode}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Select Country Code
                  </option>
                  {CountryCode.map((country) => (
                    <option key={country.code} value={country.dial_code}>
                      {country.emoji} {country.name} ({country.dial_code})
                    </option>
                  ))}
                </select>
                {errors.countryCode && (
                  <div className="text-red-500">{errors.countryCode}</div>
                )}
              </div>
              <div className="mb-[1rem]  w-[58%]">
                <label htmlFor="phoneNumber">Phone Number</label>
                <input
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  className="border p-[0.5rem] w-full"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                  pattern="[0-9]*"
                  maxLength={10}
                  onInput={(e) =>
                    (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                  }
                />
                {errors.phoneNumber && (
                  <div className="text-red-500">{errors.phoneNumber}</div>
                )}
              </div>
            </div>

            <div className="mb-[1rem]">
              <label htmlFor="service">Relevant Product</label>
              <select
                id="service"
                name="service"
                className="border p-[0.5rem] w-full"
                value={formData.service}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Select Product
                </option>
                <option value="A7_BLITZ">A7_BLITZ</option>
                <option value="A5_LIFT">A5_LIFT</option>
                <option value="A4_MSWAP">A4_MSWAP</option>
                <option value="A2_BSWAP">A2_BSWAP</option>
                <option value="A1_QUIN">A1_QUIN</option>
                <option value="A0_V.INFLUENCER">A0_V.INFLUENCER</option>
                <option value="A0_V.INFLUENCER">Others</option>
              </select>
              {errors.service && (
                <div className="text-red-500">{errors.service}</div>
              )}
            </div>
            <div className="mb-[1rem]">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                className="border p-[0.5rem] w-full"
                value={formData.message}
                onChange={handleInputChange}
              ></textarea>
            </div>
            <button
              type="button"
              className="bg-gray-900 text-white p-[1rem] rounded hover:bg-gray-500"
              onClick={handleSubmit}
            >
              Send a message
            </button>
          </div>
        </div>

        {isPopupVisible && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white flex flex-col gap-[3rem] p-8 rounded-lg shadow-md text-center relative">
              <div className="text-2xl font-semibold text-green-600">
                Thank you for contacting us !
              </div>
              <div className="text-2xl font-semibold text-green-400 pb-[3rem]">
                Team NeuroPixel will connect you soon
              </div>
              <div className=" ">
              <button
                className="bg-blue-500 text-white px-4 py-2 mt-4 rounded-full flex items-center justify-center absolute bottom-4 left-1/2 transform -translate-x-1/2"
                onClick={closePopup}
              >
                Close
              </button>
            </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactForm;
