import React from "react";
import privacyLogo from "../../Images/sidebarlogo/PrivacyImg.svg";
import TermsLogo from "../../Images/sidebarlogo/TermsImg.png";
import RefundLogo from "../../Images/sidebarlogo/RefundImg.svg";

const legalItems = [
  {
    imgSrc: TermsLogo,
    alt: "TermsLogo",
    label: "Terms of Use",
    link: "/terms-of-use",
  },
  {
    imgSrc: privacyLogo,
    alt: "PrivacyLogo",
    label: "Privacy Policy",
    link: "/privacy-policy",
  },
  {
    imgSrc: RefundLogo,
    alt: "RefundLogo",
    label: "Refund Policy",
    link: "/refund-policy",
  },
];

export default function Legal() {
  return (
    <div
      className="block pb-[1rem] md:pb-[1rem]"
      style={{ fontFamily: "Montserrat, sans-serif" }}
    >
      <div className="w-full flex items-center justify-left md:pl-[3rem] text-[2rem] bg-[#0CB6D8] p-[1rem] text-white font-bold">
        Legal
      </div>
      <div className="flex flex-row gap-[1vw] items-center justify-between w-full md:px-[2vw] md:py-[1vw] px-[5vw] py-[2vw]">
        {legalItems.map(({ imgSrc, alt, label, link }) => (
          <div
            key={label}
            className="flex flex-col gap-[0.3vw] items-center justify-center p-[5vw] md:p-[2vw] md:py-[1.5vw] border border-[#98A2B3] rounded cursor-pointer"
            onClick={() => window.open(link, "_blank")}
          >
            <img
              src={imgSrc}
              alt={alt}
              className="md:h-[2vw] md:w-[2vw] h-[9vw] w-[9vw]"
            />
            <div className="text-[2vw] md:text-[1vw] ">{label}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
