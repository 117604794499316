import React, { Component } from "react";
import video1 from "../../Images/Video.mp4";
import PlayButton from "../../Images/PlayButton.svg";
import PauseButton from "../../Images/PauseButton.svg";

class VideoPlayer extends Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
    this.progressBarRef = React.createRef();
    this.state = {
      playing: true,
      currentTime: 0,
      duration: 0,
      showControls: false,
    };
  }

  componentDidMount() {
    const video = this.videoRef.current;

    video.addEventListener("loadedmetadata", this.handleMetadata);
    video.addEventListener("timeupdate", this.handleTimeUpdate);

    // Start video when window gains focus
    window.addEventListener("visibilitychange", this.handleVisibilityChange);
  }

  componentWillUnmount() {
    const video = this.videoRef.current;

    video.removeEventListener("loadedmetadata", this.handleMetadata);
    video.removeEventListener("timeupdate", this.handleTimeUpdate);

    // Clean up event listener
    window.removeEventListener("visibilitychange", this.handleVisibilityChange);
  }

  handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      this.videoRef.current.play();
      this.setState({ playing: true });
    } else {
      this.videoRef.current.pause();
      this.setState({ playing: false });
    }
  };

  handleMetadata = () => {
    this.setState({ duration: this.videoRef.current.duration });
  };

  handleTimeUpdate = () => {
    this.setState({ currentTime: this.videoRef.current.currentTime });
  };

  togglePlay = () => {
    const video = this.videoRef.current;
    if (video.paused) {
      video.muted = false; // Unmute the video when playing
      video.play();
      this.setState({ playing: true });
    } else {
      video.pause();
      this.setState({ playing: false });
    }
  };

  toggleControls = () => {
    this.setState((prevState) => ({ showControls: !prevState.showControls }));
  };

  handleScrub = (e) => {
    const progressBar = this.progressBarRef.current;
    const scrubTime =
      (e.nativeEvent.offsetX / progressBar.offsetWidth) *
      this.videoRef.current.duration;
    this.videoRef.current.currentTime = scrubTime;
  };

  render() {
    const { playing, currentTime, duration, showControls } = this.state;
    const progressBarWidth = duration
      ? `${(currentTime / duration) * 100}%`
      : "0%";

    return (
      <div
        className="relative flex justify-center items-center shadow-[0px_0px_20px_20px_rgba(48,48,48,0.04)] "
        onMouseEnter={this.toggleControls}
        onMouseLeave={this.toggleControls}
      >
        <div className="w-full border-[0.2vw] border-white rounded-[0.7vw]">
          <video
            ref={this.videoRef}
            src={video1}
            className="w-[60vw] h-auto rounded-[0.4vw]"
            autoPlay
            muted // Initially muted
          />
          {showControls && (
            <button
              onClick={this.togglePlay}
              className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  py-[0.2vh] px-[0.9vw] transition-opacity duration-300 rounded-[1vw]`}
            >
              {playing ? (
                <div className="flex bg-white justify-center items-center rounded-[50vw] px-[1vw] py-[2vh]">
                  <img
                    src={PauseButton}
                    alt="PauseButton"
                    className="w-[2vw] object-contain"
                  />
                </div>
              ) : (
                <div className="flex bg-white justify-center items-center rounded-[50vw] px-[1vw] py-[2vh]">
                  <img
                    src={PlayButton}
                    alt="PlayButton"
                    className="w-[2vw] object-contain"
                  />
                </div>
              )}
            </button>
          )}
        </div>
        {/* Video Progress Bar (Scrub functionality) */}
        <div
          ref={this.progressBarRef}
          className="absolute bottom-0 left-0 w-full h-[0.3vw] bg-gray-200 cursor-pointer"
          onClick={this.handleScrub}
        >
          <div
            className="h-full bg-red-500"
            style={{ width: progressBarWidth }}
          ></div>
        </div>
      </div>
    );
  }
}

export default VideoPlayer;
