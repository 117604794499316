import React from "react";

export default function About() {
  return (
    <div
      className="block pt-[4rem] pb-[5rem] md:pb-[1rem]"
      style={{ fontFamily: "Montserrat, sans-serif" }}
    >
      <div className="w-full flex items-center justify-left md:pl-[3rem] text-[2rem] bg-[#0CB6D8] p-[1rem] text-white font-bold">
        About Us
      </div>
      <div className="p-[1.5rem] pt-[5rem] md:p-[3.5rem] md:pl-[4.8rem] pb-0 block">
        <div className="mb-[30px] text-[1.3rem] md:text-[1.3rem]">
          Hey there !
        </div>
        <div className="mt-[2rem] mb-[20px] text-[1.2rem]">
          We are NeuroPixel, a team of diverse professionals dedicated to
          delivering the best Generative-AI tools for fashion brands and
          platforms. Our hypothesis is that these tools are well positioned to
          become valuable growth levers while simultaneously reducing costs by a
          significant margin.
        </div>
        <div className="mt-[2rem] mb-[20px] text-[1.2rem]">
          We do this with a keen focus on deploying cutting-edge research,
          specifically our proprietary IP centred around Deep Neural Networks.
        </div>
        <div className="mt-[2rem] mb-[20px] text-[1.2rem]">
          Key highlights of our technology:
          <ul style={{ listStyleType: "disc" }} className="pl-[4rem] pt-[2rem]">
            <li>
              We preserve the colour and fall of apparel to avoid any change in
              return rates.
            </li>
            <li>
              {" "}
              To mitigate legal risks, every synthetic human image we generate
              is verified by a third-party facial recognition filter to prevent
              accidental resemblance.
            </li>
            <li>
              Currently, we operate via a Service model and are partnered with
              over a dozen global brands and platforms
            </li>
            <li>
              We believe in the synergy of People+AI to unlock exponential value
            </li>
            <li>
              Within a couple of months, a few of our solutions will be
              available via a self-service platform.
            </li>
          </ul>
        </div>
        <div className="mt-[2rem] mb-[20px] text-[1.2rem]">
          Contact us for a free trial and put us to the test. We look forward to
          hearing from you.
        </div>

        <div className="mt-[2rem] text-[1.2rem]">Team NeuroPixel</div>
      </div>
    </div>
  );
}
