import React from "react";
import Logo3 from "../../Images/ClientLogo/1.png";
import Logo4 from "../../Images/ClientLogo/Logo (4).png";
import Logo5 from "../../Images/ClientLogo/Logo (5).png";
import Logo6 from "../../Images/ClientLogo/Logo (6).png";
import Logo7 from "../../Images/ClientLogo/Logo (7).png";
import Logo8 from "../../Images/ClientLogo/Logo (8).png";
import Logo10 from "../../Images/ClientLogo/Logo (10).png";
import Logo11 from "../../Images/ClientLogo/Logo.png";
import Logo12 from "../../Images/ClientLogo/1.png";
import Logo13 from "../../Images/ClientLogo/2.png";
import Logo14 from "../../Images/ClientLogo/3.png";
import Logo15 from "../../Images/ClientLogo/4.png";
import Logo16 from "../../Images/ClientLogo/5.png";

const HorizontalCarousel = () => {
  const images = [
    Logo3,
    Logo4,
    Logo5,
    Logo6,
    Logo7,
    Logo8,
    Logo10,
    Logo11,
    Logo12,
    Logo13,
    Logo14,
    Logo15,
    Logo16,
  ];

  return (
    <div className="w-[100vw] flex items-center justify-center overflow-hidden">
      <div className="flex animate-scrollX">
        {/* Map over the images twice to create an infinite loop */}
        {images.concat(images).map((img, index) => (
          <div
            key={index}
            className="flex-none grid place-items-center w-[15vw] p-[calc(clamp(10rem,1rem+30vmin,30rem)/10)]"
          >
            <img
              src={img}
              alt={`Image ${index + 1}`}
              className="object-contain h-[10vh]"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default HorizontalCarousel;
