import React, { useState } from "react";
import TechBackgroundImage from "../../Images/TechBackground.png";
import Content1 from "../../Images/TechnologyTabImages/Content 01.png";
import Content2 from "../../Images/TechnologyTabImages/Content 02.png";
import Content3 from "../../Images/TechnologyTabImages/Content 03.png";
import Content4 from "../../Images/TechnologyTabImages/Content 04.png";

import Output1 from "../../Images/TechnologyTabImages/Tech Images/DigitSculptor_After.jpg";
import Output2 from "../../Images/TechnologyTabImages/Tech Images/DigitSculptor_Before.jpg";

import Output3 from "../../Images/TechnologyTabImages/Tech Images/LightSync_After.jpg";
import Output4 from "../../Images/TechnologyTabImages/Tech Images/LightSync_Before.jpg";

import Output5 from "../../Images/TechnologyTabImages/Tech Images/PixelMerge_After.jpg";
import Output6 from "../../Images/TechnologyTabImages/Tech Images/PixelMerge_before.jpg";

import Output7 from "../../Images/TechnologyTabImages/Tech Images/PixelShift_After.jpg";
import Output8 from "../../Images/TechnologyTabImages/Tech Images/PixelShift_Before.jpg";

const ImageComparison = ({
  contentImage,
  beforeImage,
  afterImage,
  beforeText,
  afterText,
}) => {
  return (
    <div className="flex w-full justify-between items-start gap-[5vw]">
      <div className="flex justify-start items-start pt-[12vh]">
        <img
          src={contentImage}
          alt="Content"
          className="w-[25vw] object-contain"
        />
      </div>
      <div className="flex justify-center items-center gap-[3vw]">
        <div className="flex relative justify-center items-center">
          <img
            src={beforeImage}
            alt="Before"
            className="w-[25vw] object-contain"
          />
          <div className="flex justify-center items-center bg-[#0CB6D8] px-[0.3vw] py-[0.2vh] rounded-[0.2vw] absolute top-[1vh] text-[0.7vw] left-[0.5vw]">
            {beforeText}
          </div>
        </div>
        <div className="flex relative justify-center items-center">
          <img
            src={afterImage}
            alt="After"
            className="w-[25vw] object-contain"
          />
          <div className="flex justify-center items-center bg-[#0CB6D8] px-[0.3vw] py-[0.2vh] rounded-[0.2vw] absolute top-[1vh] text-[0.7vw] left-[0.5vw]">
            {afterText}
          </div>
        </div>
      </div>
    </div>
  );
};

export default function Technology() {
  const [activeTab, setActiveTab] = useState(0); // Track the active tab

  const tabs = [
    { title: "LIGHTSYNC", subtitle: "Lightmap based conditional generation" },
    { title: "DIGIT SCULPTOR", subtitle: "Perfect fingers & Toes" },
    { title: "PIXEL-SHIFT", subtitle: "Fine-tuned apparel segmentation" },
    { title: "PIXEL-MERGE", subtitle: "Immersion & Blending" },
  ];

  return (
    <div
      style={{
        backgroundImage: `url(${TechBackgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "110vh",
        width: "100%",
      }}
    >
      <div className="w-full h-full flex flex-col  py-[8vh] px-[7vw]">
        <div className="flex flex-col justify-start items-start gap-[0vh]">
          <span className="text-[3vw] font-bold text-white uppercase leading-[7vh]">
            NeuroPixel’s Tech Edge
          </span>
          <span className="text-[1.2vw] text-white">
            It took us 3 months to get half decent output. It took us 3 years to
            get exceptional ones.
          </span>
        </div>

        <div className="flex w-full justify-start items-center gap-[2vw] mt-[2vh]">
          {tabs.map((tab, index) => (
            <button
              key={index}
              onClick={() => setActiveTab(index)}
              className={`px-[1vw] py-[1.1vh] flex flex-col items-center w-[24vw] border-[0.1vw] border-[#0CB6D8] ${
                activeTab === index ? "bg-[#0CB6D8] text-white" : "text-white"
              } rounded  duration-300`}
            >
              <span className="uppercase text-[1vw] font-semibold leading-[2.5vh]">
                {tab.title}
              </span>
              <span className="text-[0.8vw]">{tab.subtitle}</span>
            </button>
          ))}
        </div>

        <div className="text-white pt-[5vh]">
          {activeTab === 0 && (
            <ImageComparison
              contentImage={Content1}
              beforeImage={Output4}
              afterImage={Output3}
              beforeText="Before"
              afterText="After"
            />
          )}
          {activeTab === 1 && (
            <ImageComparison
              contentImage={Content2}
              beforeImage={Output2}
              afterImage={Output1}
              beforeText="Before"
              afterText="After"
            />
          )}
          {activeTab === 2 && (
            <ImageComparison
              contentImage={Content3}
              beforeImage={Output8}
              afterImage={Output7}
              beforeText="Before"
              afterText="After"
            />
          )}
          {activeTab === 3 && (
            <ImageComparison
              contentImage={Content4}
              beforeImage={Output6}
              afterImage={Output5}
              beforeText="Before"
              afterText="After"
            />
          )}
        </div>
      </div>
    </div>
  );
}
