import React from "react";
import Blackberrys from "../../Images/TestimonialImgs/BlackBerry.png";
import ArvindFashions from "../../Images/TestimonialImgs/ArvindFashions.png";
import Yukio from "../../Images/TestimonialImgs/Yukio.png";

import BlackberryLogo from "../../Images/TestimonialImgs/image 1.png";
import ArvindFashionsLogo from "../../Images/TestimonialImgs/image 15.png";
import YukioLogo from "../../Images/TestimonialImgs/Yukio 1.png";

export default function Testimonial() {
  return (
    <div className="flex flex-col justify-center items-center gap-[4vh] h-[94vh] bg-[#FAFAFA]">
      <div className="flex items-center justify-center  text-[#303030] w-full text-[2vw] font-[600]">
        Testimonials
      </div>
      <div className="flex justify-center items-center gap-[2vw]">
        <div className="p-[0.1vw] rounded-[0.1vw]  rounded-tr-[5vw]  w-[25vw] shadow-[0px_0px_20px_20px_rgba(48,48,48,0.04)]">
          <div className="bg-white px-[2vw] py-[2vh] flex-col rounded-tr-[5vw]">
            <img
              src={BlackberryLogo}
              alt="BlackberryLogo"
              className="w-[7vw] pb-[5.1vh]"
            />
            <span className="text-[#475467] leading-[2vh]">
              I would like to express my utmost appreciation for the outstanding
              creatives delivered by Neuropixel. Their creative images have
              completely transformed the look and feel of our website, bringing
              a fresh, vibrant aesthetic that truly resonates with our brand
              identity. We couldn't be more satisfied and highly recommend their
              services!
            </span>
            <div className="flex pt-[6vh] justify-start items-start gap-[1vw]">
              <img
                src={Blackberrys}
                alt="Model"
                className="w-[3vw] rounded-[50vw]"
              />
              <div className="flex flex-col justify-start items-start gap-[0.5vh]">
                <span className="text-[1vw]">Paritosh Bindra</span>
                <span className="text-[0.7vw]">Blackberrys Pvt Ltd</span>
              </div>
            </div>
          </div>
        </div>
        <div className="p-[0.1vw] rounded-[0.1vw]  rounded-tr-[5vw]  w-[25vw] shadow-[0px_0px_20px_20px_rgba(48,48,48,0.04)]">
          <div className="bg-white px-[2vw] py-[4vh]  flex-col rounded-tr-[5vw]">
            <img
              src={ArvindFashionsLogo}
              alt="ArvindFashionsLogo"
              className="w-[15vw] pb-[2vh]"
            />
            <span className="text-[#475467]  leading-[2vh]">
              Partnering with NeuroPixel has revolutionized our visual content
              creation, significantly reducing lifestyle and outdoor shoot costs
              while boosting click-through rates. Their innovative technology
              allows us to seamlessly integrate any lifestyle background,
              resulting in stunningly realistic visuals that elevate our brand's
              aesthetic.
            </span>
            <div className="flex pt-[6vh] justify-start items-start gap-[1vw]">
              <img
                src={ArvindFashions}
                alt="Model"
                className="w-[3vw] rounded-[50vw]"
              />
              <div className="flex flex-col justify-start items-start gap-[0.5vh]">
                <span className="text-[1vw]">Pratibha Negi</span>
                <span className="text-[0.7vw]">Arvind Fashions</span>
              </div>
            </div>
          </div>
        </div>
        <div className="p-[0.1vw] rounded-[0.1vw]  rounded-tr-[5vw]  w-[25vw] shadow-[0px_0px_20px_20px_rgba(48,48,48,0.04)]">
          <div className="bg-white px-[2vw] py-[2vh] flex-col rounded-tr-[5vw] ">
            <img src={YukioLogo} alt="YukioLogo" className="w-[8vw] pb-[2vh]" />
            <span className="text-[#475467]">
              Yukio is a Japanese inspired brand for which I needed Japanese
              models and backgrounds to make the brand feel authentic. This was
              not only possible because of Neuropixel but content creation
              became 200% cheaper. Image generation with Neuropixel was a game
              changer for my brand.
            </span>
            <div className="flex pt-[10vh] justify-start items-start gap-[1vw]">
              <img src={Yukio} alt="Model" className="w-[3vw] rounded-[50vw]" />
              <div className="flex flex-col justify-start items-start gap-[0.5vh]">
                <span className="text-[1vw]">Aashna</span>
                <span className="text-[0.7vw]">Yukio</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
