import React, { useState, useEffect } from "react";
import ContactUsGen1 from "../../Images/ContactUsImg.AI/Images-1.png";
import ContactUsGen2 from "../../Images/ContactUsImg.AI/Images-2.png";
import ContactUsGen3 from "../../Images/ContactUsImg.AI/Images-3.png";
import ContactUsGen4 from "../../Images/ContactUsImg.AI/Images.png";
import axios from "axios";

export default function ContactUs() {
  const imgList = [ContactUsGen1, ContactUsGen2, ContactUsGen3, ContactUsGen4];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [loading, setLoading] = useState(false); // Loading state
  const [successMessage, setSuccessMessage] = useState(""); // Success message state

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === imgList.length - 1 ? 0 : prevIndex + 1
      );
    }, 1500);

    return () => clearInterval(intervalId);
  }, [imgList.length]);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    company: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false, // Reset error when the user types
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, company, message } = formData;
    let newErrors = {
      name: name === "",
      email: email === "",
      company: company === "",
    };

    setErrors(newErrors);

    const noErrors = !Object.values(newErrors).includes(true);
    if (noErrors) {
      setLoading(true); // Set loading state to true

      try {
        const response = await axios.post(
          "https://neuropixel-website-master.onrender.com/send-email",
          {
            firstName: name,
            companyName: company,
            email: email,
            message: message,
          }
        );

        // Show success message after successful email sending
        setSuccessMessage("Email sent successfully!");

        // Clear form after successful submission
        setFormData({
          name: "",
          email: "",
          company: "",
          message: "",
        });
      } catch (error) {
        console.error(
          "Error details:",
          error.response ? error.response.data : error.message
        );
        setSuccessMessage("Error sending email. Please try again.");
      } finally {
        setLoading(false); // Stop loading state
      }
    }
  };

  return (
    <div
      className="flex w-[100vw] h-[100vh] justify-start items-start overflow-y-hidden"
      style={{
        background: "linear-gradient(90deg, #0CB6D857 0%, #1ACC7641 100%)",
      }}
    >
      <div className="w-[40vw] h-[100vh] flex justify-start items-start">
        <img
          src={imgList[currentImageIndex]} // Display the current image based on index
          alt="ContactUsGen"
          className="w-full object-contain"
        />
      </div>

      <div className="flex flex-col justify-center items-center w-[60vw]  h-full">
        <div className="flex flex-col justify-start items-start w-[30vw] bg-white px-[2vw] py-[4vh] gap-[4vh]">
          <div className="flex flex-col gap-[0.5vh] items-start justify-center">
            <h1 className="text-[2vw] text-[#303030] ">Get in Touch</h1>
            <h1 className="text-[1vw] text-[#434444]">
              Schedule a demo or explore a custom engagement
            </h1>
          </div>

          <form
            className="w-full flex flex-col gap-[1vh]"
            onSubmit={handleSubmit}
          >
            {/* Name Field */}
            <div className="relative">
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Name *"
                className={`w-full p-[0.5vw] border ${
                  errors.name ? "border-red-500" : "border-gray-300"
                } rounded-[0.5vw] text-[1vw]`}
              />
              {errors.name && (
                <p className="text-red-500 text-[0.8vw] mt-[0.4vh]">
                  Name is required
                </p>
              )}
            </div>

            {/* Email Field */}
            <div className="relative">
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Email *"
                className={`w-full p-[0.5vw] border ${
                  errors.email ? "border-red-500" : "border-gray-300"
                } rounded-[0.5vw] text-[1vw]`}
              />
              {errors.email && (
                <p className="text-red-500 text-[0.8vw] mt-[0.4vh]">
                  Email is required
                </p>
              )}
            </div>

            {/* Company Field */}
            <div className="relative">
              <input
                type="text"
                name="company"
                value={formData.company}
                onChange={handleInputChange}
                placeholder="Company *"
                className={`w-full p-[0.5vw] border ${
                  errors.company ? "border-red-500" : "border-gray-300"
                } rounded-[0.5vw] text-[1vw]`}
              />
              {errors.company && (
                <p className="text-red-500 text-[0.8vw] mt-1">
                  Company is required
                </p>
              )}
            </div>

            {/* Message Field */}
            <div className="relative">
              <textarea
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                placeholder="Message"
                className="w-full p-[1vw] h-[15vh] border border-gray-300 rounded-md text-[1vw]"
              />
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className={`w-full px-[1vw] py-[1.5vh] mt-[2vh] bg-[#0CB6D8] text-white rounded-md text-[1vw] ${
                loading ? "cursor-not-allowed opacity-50" : ""
              }`}
              disabled={loading} // Disable button while loading
            >
              {loading ? "Sending..." : "Submit"}
            </button>
          </form>

          {/* Success Message Popup */}
          {successMessage && (
            <>
              <div className="text-[1vw] font-semibold text-green-600">
                Thank you for contacting us ! Team NeuroPixel will connect you
                soon
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
