import React from "react";
import VerticalCarousel from "../Components/VerticalCarousel";

export default function Home() {
  return (
    <div
      className="flex justify-between items-center h-[100vh] w-[100vw]"
      style={{
        background: "linear-gradient(90deg, #1ACC7605  0%,  #0CB6D83D 100%)",
      }}
    >
      <div className="flex flex-col justify-start items-start pl-[5vw]">
        <h1
          className="text-[4vw] font-[700] leading-[8vh]"
          style={{
            background:
              "linear-gradient(90deg, #0CB6D8D9  0%,  #1ACC76D9 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Create photorealistic
        </h1>
        <h1
          className="text-[4vw] font-[700] leading-[9vh]"
          style={{
            background:
              "linear-gradient(90deg,  #0CB6D8D9  0%,  #1ACC76D9 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          on-model images
        </h1>
        <h1
          className="text-[4vw] font-[700] leading-[8vh]"
          style={{
            background:
              "linear-gradient(90deg,  #0CB6D8D9  0%,  #1ACC76D9 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          that drive conversion
        </h1>

        <h1 className="text-[1.3vw] pt-[2vh]">
          A suite of Generative-AI solutions featuring synthetic humans,
        </h1>
        <h1 className="text-[1.3vw] text-start">
          tailored specifically for fashion e-commerce
        </h1>

        <button
          className="flex justify-center items-center px-[2vw] py-[1.5vh] mt-[4vh] border-[0.1vw] rounded-[0.2vw] text-white"
          style={{
            background:
              "linear-gradient(90deg, #0CB6D8D9  0%,  #1ACC76D9 100%)",
          }}
          onClick={() => {
            window.open("https://tool.neuropixel.ai/sign-up");
          }}
        >
          GET STARTED
        </button>
      </div>
      <VerticalCarousel />
    </div>
  );
}
