import React from "react";
import TickIcon from "../../Images/Tick.svg";
import { useNavigate } from "react-router-dom";

const PricingCard = ({ title, price, description, features, buttonText }) => {
  const navigate = useNavigate();
  return (
    <div className={`bg-white relative px-[1vw] p-[1.4vw] flex flex-col gap-[1vw] w-[21vw] h-[60vh] border-[0.12vw] rounded ${buttonText === "Get Started" && price === "₹ 1599/month" ?"border-[#0CB6D8]":"border-[#D0D8DD]"} `}>
      <div className="flex flex-col items-center justify-center  border-b-[0.1vw] pb-[1vw]">
        <div className="flex flex-col gap-[0.4vw]">
          <h1 className="text-[1.4vw] text-[#0CB6D8] font-[700] text-center">
            {title}
          </h1>
          <h1 className="text-[1.1vw] text-[#475467] font-[600] text-center">
            {price}
          </h1>
        </div>
        <div className="flex flex-col items-center justify-center ">
          <h1 className="text-[0.9vw] text-[#475467] pt-[0.2vw]">
            {description}
          </h1>
        </div>
      </div>

      <div className="flex flex-col justify-center gap-[0.5vw] pb-[1vw]">
        <h1 className="text-[#303030] text-[0.9vw] font-[600]  text-start">
          {features.headline}
        </h1>
        <div className="flex flex-col gap-[0.5vw]">
          {features.items.map((text, index) => (
            <div
              key={index}
              className="flex gap-[0.5vw] text-[0.7vw] items-start"
            >
              <img
                src={TickIcon}
                alt="TickIcon"
                className="w-[1.2vw] h-[1.2vw]"
              />
              <div className="text-[0.9vw] text-start">{text}</div>
            </div>
          ))}
        </div>
      </div>
      {buttonText === "Get Started" && price === "₹ 1599/month" ? (
        <button
          className="absolute bottom-[1.5vw] text-center border border-[#0CB6D8] bg-[#0CB6D8] text-white  w-[18vw] py-[0.9vw] rounded text-[0.9vw] mx-[0.5vw]"
          onClick={() => {
            window.open("https://tool.neuropixel.ai/sign-up");
          }}
        >
          {buttonText}
        </button>
      ) : (
        <button
          className="absolute bottom-[1.5vw] text-center border border-[#0CB6D8] text-[#0CB6D8] bg-white  w-[18vw] py-[0.9vw] rounded text-[0.9vw] mx-[0.5vw]"
          onClick={() => {
            window.open("https://tool.neuropixel.ai/sign-up");
          }}
        >
          {buttonText}
        </button>
      )}
    </div>
  );
};

export default function PricingPage() {
  let PricingData = [
    {
      title: "Explorer",
      price: "Free",
      description: "For Experimentation",
      features: {
        headline:
          "Try out our full suite of solutions including LIFT, MSWAP, BSWAP and QUIN*.",
        items: [
          "3 Credits to experiment",
          "50+ synthetic humans",
          "1000+ curated environments",
          "2 working days turnaround time for manual post-production work",
        ],
      },
      buttonText: "Get Started",
    },
    {
      title: "Essential",
      price: "₹ 1599/month",
      description: "For Small Fashion Brands",
      features: {
        items: [
          "20 Credits per month",
          "50+ synthetic humans",
          "1000+ curated environments",
          "Post-production TAT: 2 Working Days",
        ],
      },
      buttonText: "Get Started",
    },
    {
      title: "PRO",
      price: "₹ 7999/month",
      description: "For Fashion Brands, Studios & Agencies",
      features: {
        items: [
          "120 Credits per month",
          "100+ synthetic humans",
          "2000+ curated environments",
          "Post-production TAT: 1 Working Day",
          "Add up to 5 collaborators to use a shared pool of credits",
        ],
      },
      buttonText: "Get Started",
    },
    {
      title: "ENTERPRISE",
      price: "Let’s talk!",
      description: "For Marketplaces & Conglomerates",
      features: {
        items: [
          "Unlimited usage",
          "Deployed locally on your server",
          "Models and backgrounds exclusive to your brand",
        ],
      },
      buttonText: "Get In Touch",
    },
  ];

  return (
    <div
      className="w-[100vw] overflow-hidden flex flex-col gap-[2vh] justify-center items-center text-[0.9vw] pb-[20vh] pt-[12vh]  text-[#475467]"
      style={{
        background: "linear-gradient(90deg, #1ACC7605  100%,  #0CB6D80B 0%)",
        fontFamily: "DM Sans",
      }}
    >
      <div className="flex justify-center items-center w-full">
        <div className="flex flex-col gap-[0vw] items-center justify-center">
          <h1 className="text-[2.1vw] font-semibold">Choose Your Plan</h1>
          <h1 className="text-[1vw]">
            A usage based monthly subscription model. You use credits to
            download watermark-free images once you’re satisfied.
          </h1>
        </div>
      </div>

      <div className="flex gap-[2vw] items-center justify-center pt-[3vH]">
        {PricingData.map((card, index) => (
          <PricingCard
            key={index}
            title={card.title.toUpperCase()}
            price={card.price}
            description={card.description}
            features={card.features}
            buttonText={card.buttonText}
          />
        ))}
      </div>

      <div className="flex gap-[2vw] items-center justify-center w-full pt-[6vh]">
        <div className="flex flex-col gap-[3vh] bg-[#F0FCF6] p-[1.5vw] rounded-[0.4vw] h-[35vh] w-[43.8vw] border border-[#12B76A]">
          <h1 className="text-[1.2vw] text-[#303030] font-semibold text-start">
            Subscription Details
          </h1>
          <div className="flex flex-col gap-[0.4vh]">
            {[
              "Top-up packs will be available in between billing cycles",
              "Cancel your subscription anytime. Access will remain till the end of your billing cycle",
              "Unused credits are carried forward to the next month so long as you have an active subscription",
              "No questions asked refund policy in the first month if you haven’t used your credits",
            ].map((item, index) => (
              <div className="flex gap-[1vw] items-start" key={index}>
                <img
                  src={TickIcon}
                  alt="TickIcon"
                  className="w-[1.2vw] h-[1.2vw]"
                />
                <div className="text-[0.9vw] text-start">{item}</div>
              </div>
            ))}
          </div>

          <button className="flex  w-[10vw] justify-center items-center text-center p-[0.5vw] border border-[#12B76A] rounded-[2vw] -white">
            Pricing FAQs
          </button>
        </div>

        <div className="flex flex-col gap-[0.2vh] bg-[#FEF3E8] px-[1.5vw] py-[4vh]  rounded-[0.4vw] w-[43.8vw] h-[35vh]  border border-[#F19131]">
          <div className="flex justify-between items-center">
            <h1
              className="text-[1.2vw] text-[#303030]  text-start"
              style={{
                "font-weight": "600",
              }}
            >
              Credit Conversion Ratios
            </h1>
            {/* <div className="text-[0.9vw] text-[#303030] underline font-[500]">
              View Samples
            </div> */}
          </div>

          <table className="w-full text-center">
            <thead>
              <tr>
                <th className="py-[2vh] border-b border-[#F19131]"></th>
                <th className="py-[2vh] border-b border-[#F19131]">1 Credit</th>
                <th className="py-[2vh] border-b border-[#F19131]">2 Credits</th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-[#FCEDDF]">
                <td className="py-[0.6vh]">MSWAP</td>
                <td className="py-[0.6vh]">1 Image</td>
                <td className="py-[0.6vh]">Upto 4 Image/SKU</td>
              </tr>
              <tr>
                <td className="py-[0.6vh]">BSWAP</td>
                <td className="py-[0.6vh]">1 Image</td>
                <td className="py-[0.6vh]">Upto 4 Image/SKU</td>
              </tr>
              <tr className="bg-[#FCEDDF]">
                <td className="py-[0.6vh]">LIFT</td>
                <td className="py-[0.6vh]">1 Image</td>
                <td className="py-[0.6vh]"></td>
              </tr>
              <tr>
                <td className="py-[0.6vh]">QUIN</td>
                <td className="py-[0.6vh]">2 Image</td>
                <td className="py-[0.6vh]"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
