import React, { lazy, Suspense } from "react";
import "./App.css";
import "@fontsource/poppins"; // Defaults to weight 400
import "@fontsource/poppins/400.css"; // Specify weight
import "@fontsource/poppins/400-italic.css"; // Specify weight and style
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import { MantineProvider } from "@mantine/core";
import "@mantine/core/styles.css";
import Privacy from "./Pages/SideNavBar/Privacy";
import Terms from "./Pages/SideNavBar/Terms";
import Refund from "./Pages/SideNavBar/Refund";

// Lazy load the other components
const Press = lazy(() => import("../src/Pages/SideNavBar/Press"));
const Lift = lazy(() => import("../src/Pages/InsidePages/Lift"));
const Blitz = lazy(() => import("../src/Pages/InsidePages/Blitz"));
const Vinfluen = lazy(() => import("../src/Pages/InsidePages/VInfluen"));
const ManiQueen = lazy(() => import("../src/Pages/InsidePages/ManiQueen"));
const BSWAP = lazy(() => import("../src/Pages/InsidePages/BSWAP"));
const MSWAP = lazy(() => import("../src/Pages/InsidePages/MSWAP"));

const LoadingSpinner = () => (
  <div className="flex flex-col gap-[2vw] justify-center items-center h-screen">
    <div className="border-t-4 border-blue-500 border-solid rounded-full w-[10vw] h-[10vw] md:w-[8vw] md:h-[8vw] animate-spin"></div>
    <div
      className="text-gray-900 text-[4vw] md:text-[2vw] font-semibold"
      style={{ fontFamily: "popins" }}
    >
      Loading .....
    </div>
  </div>
);

function App() {
  return (
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <div
                className="md:h-full md:w-[100vw]  w-full h-screen  text-gray-500 !overflow-hidden md:overflow-auto md:overflow-x-hidden"
                style={{ fontFamily: "DM Sans" }}
              >
                <Home />
              </div>
            }
          />

          <Route
            path="/Lift"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <div className="h-screen w-full text-gray-500 !overflow-hidden">
                  <Lift />
                </div>
              </Suspense>
            }
          />
          <Route
            path="/Blitz"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <div className="h-screen w-full text-gray-500 !overflow-hidden">
                  <Blitz />
                </div>
              </Suspense>
            }
          />
          <Route
            path="/Influen"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <div className="h-screen w-full text-gray-500 !overflow-hidden">
                  <Vinfluen />
                </div>
              </Suspense>
            }
          />
          <Route
            path="/Quin"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <div className="h-screen w-full text-gray-500 !overflow-hidden">
                  <ManiQueen />
                </div>
              </Suspense>
            }
          />
          <Route
            path="/MSWAP"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <div className="h-screen w-full text-gray-500 !overflow-hidden">
                  <MSWAP />
                </div>
              </Suspense>
            }
          />
          <Route
            path="/BSWAP"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <div className="h-screen w-full text-gray-500 !overflow-hidden">
                  <BSWAP />
                </div>
              </Suspense>
            }
          />
          <Route
            path="/press"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <Press />
              </Suspense>
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <Privacy />
              </Suspense>
            }
          />
          <Route
            path="/terms-of-use"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <Terms />
              </Suspense>
            }
          />
          <Route
            path="/Refund-policy"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <Refund />
              </Suspense>
            }
          />
          {/* Other routes if needed */}
        </Routes>
      </Router>
    </MantineProvider>
  );
}

export default App;
