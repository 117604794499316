import React from "react";
import { useNavigate } from "react-router-dom";

export default function PressViewer() {
  const navigate = useNavigate();

  return (
    <div
      className="block pb-[1rem] md:pb-[1rem]"
      style={{ fontFamily: "Montserrat, sans-serif" }}
    >
      <div className="p-0 pb-0 block">
        <div
          className="mt-[2rem] text-[1.3rem] text-black"
          onClick={() => {
            navigate("/press");
          }}
          style={{ cursor: "pointer" }}
        >
          <div className="w-full flex items-center justify-left md:pl-[3rem] text-[2rem] bg-[#0CB6D8] p-[1rem] text-white font-bold">
            Press
          </div>
        </div>
      </div>
    </div>
  );
}