import React from "react";
import Video from "../../Images/Video.png";
import HorizontalCarousel from "../Components/HorizontalScroller";
import VideoPlayer from "../Components/VideoPlayer";

export default function Products() {
  return (
    <div
      className="flex flex-col gap-[4vh] justify-start items-center w-[100vw] pt-[10vh]"
      style={{
        background: "linear-gradient(90deg, #0CB6D81A  0%,  #1ACC7629 100%)",
      }}
    >
      <div
        className="text-center text-[2.5vw] font-[700]"
        style={{
          background:
            "linear-gradient(90deg, #0C2C33 0%, #0CA5C3 50%, #0CB6D8 100%)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        }}
      >
        How it works - Product Demo
      </div>

      <VideoPlayer />
      {/* <img src={Video} alt="Video" className="h-[50vh] object-contain" /> */}
      <div className="flex flex-col justify-center items-center pt-[4vh]">
        <div className="text-[#303030] text-[2vw] font-[700]">Our Clients</div>
        <HorizontalCarousel />
      </div>
    </div>
  );
}
