import React from "react";
import NeuropixelLogo from "../../Images/Neuropixel Logo.png";
import { useNavigate } from "react-router-dom";

export default function Privacy() {
  const navigate = useNavigate();
  return (
    <div
      className="flex flex-col gap-[1vw] w-[100vw] h-screen"
      style={{ fontFamily: "poppins" }}
    >
      <div
        className="flex justify-between items-center px-[1vw] md:py-[1vw] py-[3vw] text-white h"
        style={{
          background: "linear-gradient(90deg, #73BC65 0%, #0CB6D8 100%)",
        }}
      >
        <img
          src={NeuropixelLogo}
          alt="Neuropixel.AI"
          className="md:w-[15vw] w-[35vw] cursor-pointer"
          onClick={() => navigate("/")}
        />
      </div>

      <div className="flex flex-col gap-[1vw] justify-center items-center md:p-[1vw] p-[5vw]">
        <div className="w-full h-[20vw] md:h-[10vw] bg-[#f0f0f0] flex items-center justify-center rounded-md md:text-[2.7vw] text-[4vw] font-semibold text-black">
          Privacy Policy
        </div>
        <div className="flex flex-col  md:md:text-[0.9vw] text-[2vw] font-[400]  md:p-[1.5vw] p-[4vw]">
          <ol
            className="md:pl-[0.5vw] md:mb-[1vw] mb-[3vw] font-semibold md:text-[1.2vw] text-[2.5vw]"
            style={{
              listStyleType: "decimal",
            }}
          >
            <li className="md:mb-[1vw] mb-[3vw]">
              <h1 className="font-semibold md:text-[1.2vw] text-[2.5vw] md:mb-[1vw] mb-[3vw]">
                Scope and Application
              </h1>
              <ol
                className="list-lower-alpha list-inside pl-[0.5vw] md:mb-[1vw] mb-[3vw] md:text-[0.9vw] text-[2vw] font-[400]"
                style={{ listStyleType: "upper-roman" }}
              >
                <li className="md:mb-[1vw] mb-[3vw]">
                  The terms ‘We/Us/Our’ shall refer to Neuropixel.AI Labs
                  Private Limited, having our registered office at First Floor,
                  Urban Vault 2908, Club Road, 22nd Cross Rd, 2nd Sector,
                  Parangi Palaya, HSR Layout, Bengaluru, Karnataka India 560102,
                  and the terms ‘You/Your’ shall refer to the users/ members/
                  customers/ clients and any other persons accessing or seeking
                  to use the Platform (as defined below) and/ or availing our
                  Services (as defined below). We have created this privacy
                  statement in order to demonstrate our firm commitment to
                  privacy.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  This privacy policy is an electronic record in the form of an
                  electronic contract drafted under the (Indian) Information
                  Technology Act, 2000, and the Information Technology
                  (Reasonable security practices and procedures and sensitive
                  personal data of information) Rules, 2011, as amended from
                  time to time (“Privacy Policy”). At the same, we understand
                  and recognise our legal obligations under the General Data
                  Protection Regulation (EU) 2016/679 (the “GDPR”), and we are
                  legally obligated to protect sensitive personal data and
                  information as per the GDPR requirements as well. At this
                  point, we are as yet to ISO and GDPR certification but which
                  is targeted for Q4 2024.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  This Privacy Policy is a legally binding document between you
                  and us which governs the usage, collection, protection and
                  sharing of any Information that you provide us, when you
                  access https://www.neuropixel.ai/ (the “Platform”) and/ or use
                  our Services via the Company Application and/ or through any
                  of various mediums or devices now known or developed at a
                  later date. For this purpose, the terms “Company Application”
                  and “Services” shall have the meanings attributed to them in
                  our Terms of Use.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  This Privacy Policy shall be read together with the other
                  policies published by us on the Platform, including without
                  limitation the Terms of Use (together the “Policies”).
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  This Privacy Policy is an electronic document and does not
                  require any physical, electronic or digital signature. By
                  accessing or using the Platform and/ or availing our Services,
                  you indicate that you understand, agree and consent to the
                  terms and conditions contained in the Privacy Policy. If you
                  do not agree with the terms and conditions of this Privacy
                  Policy, please do not use this Platform or the Company
                  Application or avail our Services.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  Please note that we are not responsible for, and this Privacy
                  Policy does not govern Information provided other than through
                  the Platform or the Company Application.
                </li>
              </ol>
            </li>

            <li className="md:mb-[1vw] mb-[3vw]">
              <h1 className="font-semibold md:text-[1.2vw] text-[2.5vw] md:mb-[1vw] mb-[3vw]">
                Commitment
              </h1>
              <ol
                className="list-lower-alpha list-inside pl-[0.5vw] md:mb-[1vw] mb-[3vw] md:text-[0.9vw] text-[2vw] font-[400]"
                style={{ listStyleType: "upper-roman" }}
              >
                <li className="md:mb-[1vw] mb-[3vw]">
                  We are committed to ensure that your privacy is protected to
                  all possible, reasonable and commercial extent, as your
                  privacy and data protection is of the utmost importance to us.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  We gather certain types of Information in order to provide,
                  maintain and improve the Platform, the Company Applications
                  and the Services rendered to you; and hence, we strongly urge
                  you to fully understand the terms and conditions of the
                  Privacy Policy surrounding the capture and use of such
                  Information.
                </li>
              </ol>
            </li>

            <li className="md:mb-[1vw] mb-[3vw]">
              <h1 className="font-semibold md:text-[1.2vw] text-[2.5vw] md:mb-[1vw] mb-[3vw]">
                Collection of Information
              </h1>
              <ol
                className="list-lower-alpha list-inside pl-[0.5vw] md:mb-[1vw] mb-[3vw] md:text-[0.9vw] text-[2vw] font-[400]"
                style={{ listStyleType: "upper-roman" }}
              >
                <li className="md:mb-[1vw] mb-[3vw]">
                  For the purposes of rendering the Services and/ or use of the
                  Platform, we may collect certain Information from various
                  channels, including, but not limited to, voluntary submission
                  of information from your end in furtherance of the access or
                  usage of the Services and/ or the Platform, through requests
                  initiated by you towards the Services and through
                  communications.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  We may use your internet protocol ("IP") address to help
                  diagnose any problems (including with our servers), as well as
                  to administer our Services and the Platform. Your IP address
                  is used to help identify you and to gather broad information.
                  We may use your IP address to help protect ourselves, and our
                  partners and customers from any adverse acts. For system
                  administration purposes, we may also log IP server addresses
                  and catalogue traffic patterns through our Platform and
                  Company Applications. However, we do not link this information
                  to any users’ personal information.
                </li>

                <li className="md:mb-[1vw] mb-[3vw]">
                  Please note that we are based in the Republic of India. We are
                  governed by the (Indian) Information Technology Act, 2000;
                  however, even though it may or may not accord the same degree
                  of data protection as your home jurisdiction, our collection,
                  storage and usage of Information will continue to be governed
                  by this Privacy Policy. We will always endeavour to protection
                  your Information.
                </li>
              </ol>
            </li>

            <li className="md:mb-[1vw] mb-[3vw]">
              <h1 className="font-semibold md:text-[1.2vw] text-[2.5vw] md:mb-[1vw] mb-[3vw]">
                Types of Information collected by the Us (“Information”)
              </h1>

              <ol
                className="pl-[0.5vw] md:mb-[1vw] mb-[3vw]"
                style={{ listStyleType: "decimal", fontSize: "1.2vw" }}
              >
                <li className="md:mb-[1vw] mb-[3vw]">
                  <h1 className="font-semibold md:text-[1.2vw] text-[2.5vw] md:mb-[1vw] mb-[3vw]">
                    Personally Identifiable Information (PII)
                  </h1>
                  <ol
                    className="pl-[1.2vw] md:mb-[1vw] mb-[3vw] mt-[1vw] md:text-[0.9vw] text-[2vw] font-[400]"
                    style={{ listStyleType: "lower-alpha" }}
                  >
                    <li className="mb-[0.5vw]">
                      PII, as used in information security and privacy laws, is
                      information that can be used on its own or with other
                      information to identify, contact, or locate a single
                      person, or to identify an individual in context.
                    </li>
                    <li className="mb-[0.5vw]">
                      We may collect and retain certain PII including without
                      limitation your name, age, gender, telephone number,
                      mailing address, including but not limited to permanent
                      and current residential addresses, email address, history
                      of your transactions with us, etc. These PII are essential
                      for rendering our Services to you and for our
                      communications with you. We may also use this Information
                      to customize our Services based on such Information as
                      provided by you.
                    </li>
                    <li className="mb-[0.5vw]">
                      Any information exchanged by you with us in the form of
                      written communication, responses to emails, feedback
                      provided, participation in discussions, etc. is handled
                      with confidentiality and will be available for our
                      exclusive use.
                    </li>
                    <li className="mb-[0.5vw]">
                      You have the right to request to discontinue our use of
                      your PII. To withdraw your consent to our collection and
                      processing of your information in future, you may do so by
                      specifically informing us that you are withdrawing your
                      consent towards our use of your PII. However, please note
                      that you will need to provide us with alternate means of
                      communicating with you, if the Services are on-going in
                      nature.
                    </li>
                  </ol>
                </li>

                <li className="md:mb-[1vw] mb-[3vw]">
                  <h1 className="font-semibold md:text-[1.2vw] text-[2.5vw] md:mb-[1vw] mb-[3vw]">
                    Non-Personal Information (NPI)
                  </h1>
                  <ol
                    className="pl-[1.2vw] md:mb-[1vw] mb-[3vw] mt-[1vw] md:text-[0.9vw] text-[2vw] font-[400]"
                    style={{ listStyleType: "lower-alpha" }}
                  >
                    <li className="mb-[0.5vw]">
                      We also collect information in a form that does not permit
                      direct association with any specific individual including
                      without limitation the information of the browser you use,
                      usage details and identifying technologies (“NPI”). NPI
                      includes without limitation referring/ exit pages,
                      anonymous usage data, and URLs, platform types, number of
                      clicks, etc.). We request you to note that when you access
                      the Platform and/ or the Company Applications or receive
                      emails or any communication from us, we along with its
                      affiliated companies and permitted agents, may use cookies
                      and/or pixel tags to collect information and store your
                      online preferences.
                    </li>
                    <li className="mb-[0.5vw]">
                      If we do combine NPI with PII, then the combined
                      information will be treated as PII for as long as it
                      remains combined.
                    </li>
                    <li className="mb-[0.5vw]">
                      We use various technologies to collect NPI including
                      without limitation, cookies. A cookie is a piece of data
                      that any website can send to your browser, which may then
                      be stored on your computer as an anonymous tag that
                      identifies your computer but not you. To enhance our
                      Services, some of our webpages and applications use
                      cookies, sent by us or our third party vendors, or other
                      technologies. You may control the effect of cookies
                      through your browser settings, however some features of
                      our Services may not work properly if your use of cookies
                      is disabled. Similarly, we may use web beacon in
                      conjunction with cookies, to enhance our Services on a
                      number of pages of the Platform. A non-identifiable notice
                      of a visitor's visit to a page on the Platform is
                      generated and recorded. Such notice may be processed by us
                      or by our suppliers. To disable some of these features,
                      you may disable cookies in your web browser's settings.
                      Web beacon and other technologies will still detect visits
                      to these pages, but the notices they generate are
                      disregarded and cannot be associated with other
                      non-identifiable cookie information.
                    </li>
                    <li className="mb-[0.5vw]">
                      We may also collect other non-specific information to
                      provide you better access to the Services each time such
                      as preferences and interests and other non-personal
                      details names shared via the Platform, Company Application
                      or email or any other medium. We may also store and
                      publish such information to assist us in making the
                      Platform and Services better and easier to use.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>

            <li className="md:mb-[1vw] mb-[3vw]">
              <h1 className="font-semibold md:text-[1.2vw] text-[2.5vw] md:mb-[1vw] mb-[3vw]">
                Information Usage and Sharing
              </h1>

              <ol
                className="pl-[0.5vw] md:mb-[1vw] mb-[3vw] md:text-[0.9vw] text-[2vw] font-[400]"
                style={{ listStyleType: "decimal" }}
              >
                <li className="md:mb-[1vw] mb-[3vw]">
                  Our primary goal in collecting the Information is for us to
                  facilitate better access to the Platform and render the
                  Services to you, and to ensure quality and efficiency in the
                  Services provided to you. Additionally, we will also be using
                  the Information for our internal operational purposes, such as
                  providing, maintaining, evaluating, and improving the Platform
                  and the Services, and also for providing customer support. We
                  would also be sharing the information with others in the
                  manner provided in this Privacy Policy.
                </li>

                <li className="md:mb-[1vw] mb-[3vw]">
                  Please note that we do not and will not trade or sell your PII
                  in any manner.
                </li>

                <li className="md:mb-[1vw] mb-[3vw]">
                  We may share Your PII with third parties in the following
                  limited circumstances:
                  <ol
                    className="pl-[1.2vw] md:mb-[1vw] mb-[3vw] mt-[1vw] md:text-[0.9vw] text-[2vw] font-[400]"
                    style={{ listStyleType: "lower-alpha" }}
                  >
                    <li className="mb-[0.5vw]">
                      To provide other businesses or persons for the purpose of
                      processing PII on our behalf in connection with the
                      Services or providing you with additional services related
                      to our business and Services. We will require that these
                      parties are subject to appropriate confidentiality and
                      security measures;
                    </li>
                    <li className="mb-[0.5vw]">
                      We have a good faith belief that access, use, preservation
                      or disclosure of such PII is reasonably necessary to: (i)
                      satisfy any applicable law, regulation, legal process or
                      enforceable governmental request including to law
                      enforcement and in response to a court order, (ii) detect,
                      prevent, or otherwise address fraud, technical or security
                      issues, (iii) enforce applicable Policies of the Platform,
                      which may be further extended to investigation of
                      potential violations thereof, or (iv) protect against harm
                      to our rights, safety or property, our users or the public
                      as required or permitted by law;
                    </li>
                    <li className="mb-[0.5vw]">
                      To protect ourselves against third-party claims;
                    </li>
                    <li className="mb-[0.5vw]">
                      We may disclose/ share information to those third-parties
                      who support our business, such as providing technical
                      infrastructure services, analysing how our services are
                      used, measuring the effectiveness of the Platform and the
                      Company Applications, providing customer support and
                      business support services, facilitating payments, or
                      conducting research and surveys, and such information will
                      be used for the limited purposes of such services received
                      by us.
                    </li>

                    <li className="mb-[0.5vw]">
                      In the event that images are sent to us for
                      post-production purposes by you, the client, then the
                      images will be used by our post-production staff in a
                      masked format (without any client identifiers). The
                      post-processed image would be uploaded back to the client
                      terminal and deleted from local storage devices within a
                      period of 30 days. Retaining it for 30 days is in the
                      event of any re-work requests, we would need access to the
                      edited file.
                    </li>
                  </ol>
                </li>

                <li className="md:mb-[1vw] mb-[3vw]">
                  We may share NPI with interested third parties to help them
                  understand the usage patterns and analyses for certain
                  Services. Any NPI and data and analyses arising therefrom may
                  be shared by us to our existing and potential partners,
                  investors, service providers and others.
                </li>
              </ol>
            </li>

            <li className="md:mb-[1vw] mb-[3vw]">
              <h1 className="font-semibold md:text-[1.2vw] text-[2.5vw] md:mb-[1vw] mb-[3vw]">
                Links to/ from Third-Party Websites
              </h1>

              <ol
                className="pl-[0.5vw] md:mb-[1vw] mb-[3vw] md:text-[0.9vw] text-[2vw] font-[400]"
                style={{ listStyleType: "decimal" }}
              >
                <li className="md:mb-[1vw] mb-[3vw]">
                  The Platform and/ or the Company Application may contain links
                  to third-party websites, products, and services. Information
                  collected by third parties, which may include without
                  limitation information such as location data or contact
                  details, is governed by the privacy practices undertaken by
                  such third parties. We encourage you to learn about the
                  privacy practices of those third parties. We expressly assert
                  that we cannot be responsible for these practices.
                </li>

                <li className="md:mb-[1vw] mb-[3vw]">
                  We may have presence on social networking websites including
                  but not limited to LinkedIn, Facebook, Twitter, Instagram,
                  YouTube and blogs which are promotional and business
                  initiatives to attract, engage and connect to a larger group
                  of people. The domain links contained therein may either
                  direct you to our Platform or our Company Application or
                  request your participation by way of feedback, suggestions,
                  etc. We, in this regard, fully disclaim any liability(ies) or
                  claim(s), which may arise by use/misuse of your feedback,
                  suggestions, views, etc. on any of the aforementioned
                  networking websites or blogs, by any third party whether or
                  not known to us.
                </li>
              </ol>
            </li>

            <li className="md:mb-[1vw] mb-[3vw]">
              <h1 className="font-semibold md:text-[1.2vw] text-[2.5vw] md:mb-[1vw] mb-[3vw]">
                Information Security & Retention
              </h1>

              <ol
                className="pl-[0.5vw] md:mb-[1vw] mb-[3vw] md:text-[0.9vw] text-[2vw] font-[400]"
                style={{ listStyleType: "decimal" }}
              >
                <li className="md:mb-[1vw] mb-[3vw]">
                  We take precautions including without limitation
                  administrative, technical and physical measures to safeguard
                  your personal information against loss, misuse or theft, as
                  well as against destruction, alteration, disclosure and
                  unauthorized access.
                </li>

                <li className="md:mb-[1vw] mb-[3vw]">
                  Specifically, while we will ensure our best efforts to protect
                  your Information in line with commercially reasonable efforts
                  and general industry standards; however, we do not represent,
                  warrant, or guarantee that your Information will be protected
                  against unauthorized access, loss, misuse, or alterations
                  beyond our reasonable control.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  When you post on the Platform and/ or Company Application,
                  some of the PII you share is visible to other users and can be
                  read, collected, or used by them. You are responsible for such
                  PII you choose to submit in these instances. For example, if
                  you list your name and email address on the Platform and/ or
                  Company Application while providing comments or reviews or any
                  public feedback, that information may be public. Further, you
                  are responsible for maintaining the confidentiality of your
                  account and password, wherever applicable, and for restricting
                  access to your computer/ mobile systems, and you agree to
                  accept responsibility for all activities that occur under your
                  account or password.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  We may share your Information with third parties for the
                  purposes mentioned in Section 5 under confidentiality
                  arrangements in connection with our Services, which inter alia
                  provides for that such third parties to not further disclose
                  the Information.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  Notwithstanding anything contained in this Policy or
                  elsewhere, we shall not be held responsible for any loss,
                  damage or misuse of your PII, if such loss, damage or misuse
                  is attributable to a Force Majeure Event (as defined below):
                  <i>
                    {" "}
                    A "Force Majeure Event" shall mean any event that is beyond
                    Our reasonable control and shall include, without
                    limitation, sabotage, fire, flood, explosion, acts of God,
                    civil commotion, strikes or industrial action of any kind,
                    riots, insurrection, war, acts of government, computer
                    hacking, unauthorised access to computer, computer system or
                    computer network, computer crashes, breach of security and
                    encryption (provided beyond our reasonable commercial
                    control), power or electricity failure or unavailability of
                    adequate power or electricity.
                  </i>
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  You understand and agree that the we may continue to store
                  your Information after you cease use of the Services or
                  disable your use of, access to, or otherwise of the Services
                  or the Platform. Please note that we shall not use, share or/
                  and disclose your PII with its affiliates, vendors, third
                  parties etc., after you cease use of the Services or disable
                  your use of, access to, or otherwise of the Services or the
                  Platform, unless required by law to do so. NPI will be
                  retained indefinitely, and we may however continue to use,
                  share and/ or disclose your NPI in furtherance of our
                  Policies.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  Our Platform and/ or Company Application may provide links to
                  any other website or locations for your convenience, but the
                  provision of such links does not signify our endorsement of
                  such other website or location or its contents. We have no
                  control over, does not review, and cannot be responsible for
                  these outside websites or their content. Please be aware that
                  the terms of this Privacy Policy do not apply to these outside
                  websites or location or its contents.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  Wherever we are processing your PII under consent in the
                  manner provided in this Privacy Policy, you will always be
                  able to withdraw that consent. However, in such circumstances,
                  please note that your ability to use the Platform and/ or the
                  Services may get impacted.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  [Separately, we generally retain your PII as long as you keep
                  you are accessing our Platform and/ or availing our Services,
                  and for a period of 12 months thereafter, or if your account
                  with us has been inactive for more than 24 months. Following
                  this period, we shall delete the PII or de-identify it so that
                  it is anonymous and not attributable to any individual or
                  user.]
                </li>
              </ol>
            </li>

            <li className="md:mb-[1vw] mb-[3vw]">
              <h1 className="font-semibold md:text-[1.2vw] text-[2.5vw] md:mb-[1vw] mb-[3vw]">
                Additional rights for users located in the EU and UK
              </h1>

              <ol
                className="pl-[0.5vw] md:mb-[1vw] mb-[3vw] md:text-[0.9vw] text-[2vw] font-[400]"
                style={{ listStyleType: "decimal" }}
              >
                <li className="md:mb-[1vw] mb-[3vw]">
                  Please note that you will be entitled to all your rights under
                  General Data Protection Regulation (EU) 2016/679 (the “GDPR”).
                  If you want to exercise your rights regarding the PII, please
                  contact us at the contact details provided below.
                </li>

                <li className="md:mb-[1vw] mb-[3vw]">
                  In particular, you will have the right to be informed, the
                  right of access, the right of rectification, the right of
                  erasure and the right to object, and all other rights and
                  remedies available under the GDPR.
                </li>
              </ol>
            </li>

            <li className="md:mb-[1vw] mb-[3vw]">
              <h1 className="font-semibold md:text-[1.2vw] text-[2.5vw] md:mb-[1vw] mb-[3vw]">
                Grievances
              </h1>

              <ol
                className="pl-[0.5vw] md:mb-[1vw] mb-[3vw] md:text-[0.9vw] text-[2vw] font-[400]"
                style={{ listStyleType: "decimal" }}
              >
                <li className="md:mb-[1vw] mb-[3vw]">
                  In case of breach of security leading to the accidental or
                  unlawful destruction, loss, alteration, unauthorized
                  disclosure of, or access to, personal data transmitted, stored
                  or otherwise processed, we have the mechanisms and policies in
                  place in order to identify it and assess it promptly.
                  Depending on the outcome of our assessment, we will make the
                  requisite notifications to the supervisory authorities and
                  communications to the affected data subjects, which might
                  include you.
                </li>

                <li className="md:mb-[1vw] mb-[3vw]">
                  In accordance with the legal requirements, the name and
                  contact details of the Grievance Officer who can be contacted
                  with respect to any complaints or concerns including those
                  pertaining to breach of this Privacy Policy and other Polices
                  are published as under:
                  <br />
                  <br />
                  Data Privacy Officer/ Grievance Officer Name: Amritendu
                  <br />
                  Mukherjee Email address: amritendu@neuropixel.ai <br />
                  Phone number: +91 96321 40166
                </li>
              </ol>
            </li>

            <li className="md:mb-[1vw] mb-[3vw] md:text-[0.9vw] text-[2vw] font-[400]">
              The Data Privacy/ Grievance Officer can be contacted between 10am
              to 5pm (IST) from Monday to Friday except on public holidays.
            </li>

            <li className="md:mb-[1vw] mb-[3vw]">
              <h1 className="font-semibold md:text-[1.2vw] text-[2.5vw] md:mb-[1vw] mb-[3vw]">
                Governing Law
              </h1>

              <ol
                className="pl-[0.5vw] md:mb-[1vw] mb-[3vw] md:text-[0.9vw] text-[2vw] font-[400]"
                style={{ listStyleType: "decimal" }}
              >
                <li className="md:mb-[1vw] mb-[3vw]">
                  We are incorporated in, and based out of the Republic of
                  India, and we are duty bound to abide by Indian laws. We may
                  not have complied with some privacy laws of other countries
                  and further represent to be unaware of such other legal
                  requirements.
                </li>

                <li className="md:mb-[1vw] mb-[3vw]">
                  Further, any disputes regarding this Privacy Policy shall be
                  subject to the exclusive jurisdiction of the courts of
                  Bengaluru, India.
                </li>
              </ol>
            </li>

            <li className="md:mb-[1vw] mb-[3vw]">
              <h1 className="font-semibold md:text-[1.2vw] text-[2.5vw] md:mb-[1vw] mb-[3vw]">
                Questions and Contact Information
              </h1>

              <ol
                className="pl-[0.5vw] md:mb-[1vw] mb-[3vw] md:text-[0.9vw] text-[2vw] font-[400]"
                style={{ listStyleType: "decimal" }}
              >
                <li className="md:mb-[1vw] mb-[3vw]">
                  If you would like to access, correct, amend or delete any of
                  your Information shared with us, please register a complaint,
                  or if you want more Information about this Privacy Policy,
                  please contact us at{" "}
                  <a className="text-blue-500 underline">
                    amritendu@neuropixel.ai
                  </a>{" "}
                  or{" "}
                  <a className="text-blue-500 underline">
                    arvind@neuropixel.ai
                  </a>{" "}
                  . We shall respond to and address all reasonable concerns or
                  inquiries in a reasonable amount of time.
                </li>
              </ol>
            </li>

            <li className="md:mb-[1vw] mb-[3vw]">
              <h1 className="font-semibold md:text-[1.2vw] text-[2.5vw] md:mb-[1vw] mb-[3vw]">
                Updates to the Privacy Policy
              </h1>

              <ol
                className="pl-[0.5vw] md:mb-[1vw] mb-[3vw] md:text-[0.9vw] text-[2vw] font-[400]"
                style={{ listStyleType: "decimal" }}
              >
                <li className="md:mb-[1vw] mb-[3vw]">
                  We reserve the right to modify, amend, suspend, terminate or
                  replace this Privacy Policy from time to time within our sole
                  discretion. We will post any changes made to the Privacy
                  Policy on the Platform without prior notice to you. Such
                  changes will take effect immediately upon their posting on the
                  Platform. If we make material changes to this Privacy Policy,
                  we may notify you that it has been updated to enable you to
                  review the materially-changed Privacy Policy.
                </li>

                <li className="md:mb-[1vw] mb-[3vw]">
                  We encourage you to review the Privacy Policy frequently. By
                  your continued use of the Platform and availing of our
                  Services, you consent to the terms of the revised/ updated
                  Privacy Policy.
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
}
