import React from "react";
import NeuropixelLogo from "../../Images/Neuropixel Logo.png";
import { useNavigate } from "react-router-dom";

export default function Refund() {
  const navigate = useNavigate();
  return (
    <div
      className="flex flex-col gap-[1vw] w-[100vw] h-screen"
      style={{ fontFamily: "poppins" }}
    >
      <div
        className="flex justify-between items-center px-[1vw] md:py-[1vw] py-[3vw] text-whites"
        style={{
          background: "linear-gradient(90deg, #73BC65 0%, #0CB6D8 100%)",
        }}
      >
        <img
          src={NeuropixelLogo}
          alt="Neuropixel.AI"
          className="md:w-[15vw] w-[35vw] cursor-pointer"
          onClick={() => navigate("/")}
        />
      </div>

      <div className="flex flex-col gap-[1vw] justify-center items-center md:p-[1vw] p-[5vw]">
        <div className="w-full h-[20vw] md:h-[10vw] bg-[#f0f0f0] flex items-center justify-center rounded-md md:text-[2.7vw] text-[4vw] font-semibold text-black">
          Refund Policy
        </div>
        <div className="flex flex-col  md:text-[0.9vw] font-[400] text-[2vw] md:p-[1vw] p-[4vw]">
          <ol className="pl-[0.5vw] md:mb-[1vw] mb-[3vw] flex flex-col gap-[1.5vw]">
            <li className="md:mb-[1vw] mb-[3vw]">
              NeuroPixel AI Labs Private Limited (referred to as “we”, us”,
              “NeuroPixel”) is the author and publisher of the internet
              resource, https://neuropixel.ai/ website as well as any other
              media form, media channel, mobile website or mobile application
              related, linked, or otherwise connected software and applications
              provided by NeuroPixel (collectively, the “Site”) NeuroPixel makes
              available various categories of Services that governs your access
              and use of our video creation platform, including associated
              tools, Avatars/Models, voices, templates, functionality and
              artificial intelligence components, and other services offered by
              NeuroPixel (“Services”)
            </li>

            <li className="md:mb-[1vw] mb-[3vw]">
              This Payment Terms, Refund, and Cancellation Policy has been
              created to demonstrate our absolute commitment to customer
              satisfaction. This Policy along with the Privacy Policy and User
              Terms available together constitute a legally binding agreement
              (“Agreement”) between You and NeuroPixel and shall apply to and
              govern your visit to and use, of the Website and any of its
              products or services whether through a computer or a mobile phone
              as well as to all information, recommendations and or Services
              provided to You on or through the Site.
            </li>
            <li className="md:mb-[1vw] mb-[3vw]">
              <h1 className="font-semibold text-[2.3vw] md:text-[1.2vw] md:mb-[1vw] mb-[3vw]">
                FORM OF PAYMENT
              </h1>
              <ol
                className="pl-[5vw] md:pl-[2vw] md:mb-[1vw] mb-[3vw] md:text-[0.9vw] text-[2vw]"
                style={{ listStyleType: "disc" }}
              >
                <li className="md:mb-[1vw] mb-[3vw]">
                  NeuroPixel accepts payment through Razorpay payment gateway
                  service providers
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  You may be required to purchase or pay a monthly/yearly
                  subscription fee to access all of our services. You agree to
                  provide current, complete, and accurate purchase and account
                  information for all purchases made via the Site. You further
                  agree to promptly update account and payment information,
                  including email address, payment method, and payment card
                  expiration date, so that we can complete your transactions and
                  contact you as needed. We bill you through an online billing
                  account for purchases made via the Site. GST will be added to
                  the price of purchases as deemed required by us. We may change
                  prices at any time and shall be applicable to you in the next
                  billing cycle.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  You agree to pay all charges or fees at the prices then in
                  effect for your purchases, and you authorize us to charge your
                  chosen payment provider for any such amounts upon making your
                  purchase. If your purchase is subject to recurring charges,
                  then you consent to our charging your payment method on a
                  recurring basis without requiring your prior approval for each
                  recurring charge, until you notify us of your cancellation.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  We reserve the right to correct any errors or mistakes in
                  pricing, even if we have already requested or received
                  payment. We also reserve the right to refuse any order placed
                  through the Site.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  If the payment is not made within 7 days after the renewal
                  date, the subscription shall immediately lapse. We may
                  terminate the subscription and these terms if it is unable to
                  renew the subscription based on inaccurate or outdated credit
                  or debit card information or failure of payment at the payment
                  gateway
                </li>

                <li className="md:mb-[1vw] mb-[3vw]">
                  Discounts, credits, rebates, or other special offers may only
                  be valid for the initial term unless otherwise expressly
                  stated.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  The right of access granted under these Terms of Service is
                  effective only upon payment of the subscription fees.
                </li>
              </ol>
            </li>

            <li className="md:mb-[1vw] mb-[3vw]">
              <h1 className="font-semibold text-[2.3vw] md:text-[1.2vw] md:mb-[1vw] mb-[3vw]">
                REFUNDS AND CANCELLATION
              </h1>
              <ol
                className="pl-[5vw] md:pl-[2vw] md:mb-[1vw] mb-[3vw] md:text-[0.9vw] text-[2vw]"
                style={{ listStyleType: "disc" }}
              >
                <li className="md:mb-[1vw] mb-[3vw]">
                  The User may cancel their subscription either via the platform
                  or by emailing support@NeuroPixel.ai.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  Once You intimate your intention to cancel your membership
                  subscription, you will continue to have access to the platform
                  until your next billing date post which an active subscription
                  will be required to access your files or use our services.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  Subscription fee may be refundable in full during your first
                  month of usage in case you are as yet to use more than 5 paid
                  credits. Usage of credits would occur only when you confirm
                  that you accept the output the output generated so that you
                  may download the watermarked image. Post the first month of
                  usage, there will be no refunds available.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  The refund shall be credited back to the source account or
                  credits as selected by you within 5- 7 working days.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  To report payment-related complaints, please contact
                  support@NeuroPixel.ai
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
}
