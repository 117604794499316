import React from "react";

export default function Backers() {
  return (
    <div
      className="block pb-[5rem] md:pb-[1rem]"
      style={{ fontFamily: "Montserrat, sans-serif" }}
    >
      <div className="w-full flex items-center justify-left md:pl-[3rem] text-[2rem] bg-[#0CB6D8] p-[1rem] text-white font-bold">
        Backers
      </div>
      <div className="p-[1.5rem] pt-[5rem] md:p-[3.5rem] md:pl-[4.8rem] pb-0 block">
        <div className="mt-[2rem] text-[1.3rem] text-black">
        We’re proud to be partnered with some of the best in the business
          <div className="mb-[0.5rem] text-[1.7rem]  mt-[3rem] font-semibold">
            Investors
          </div>
          <div className="ml-[1rem] ">
            <div className="mb-[0.5rem]">- Flipkart Ventures</div>
            <div className="mb-[0.5rem]">- Entrepreneur First</div>
            <div className="mb-[0.5rem]">- Inflection Point Ventures</div>
            <div className="mb-[0.5rem]">- Dexter Angels</div>
            <div className="mb-[0.5rem]">- Huddle VC</div>
            <div className="mb-[0.5rem]">- ISB DLabs</div>
            <div className="mb-[0.5rem]">- Rishaad Currimjee (Angel)</div>
            <div className="mb-[0.5rem]">- Dhruv Toshniwal (Angel)</div>
          </div>
          <div className="mb-[0.5rem] pt-[2rem] text-[1.7rem] font-semibold">
            Accelerators
          </div>
          <div className="ml-[1rem]">
            <div className="mb-[0.5rem]">- Google for Startups</div>
            <div className="mb-[0.5rem]">- NetApp Excellerator</div>
            <div className="mb-[0.5rem]">- Reliance JioGenNext</div>
            <div className="mb-[0.5rem]">- Graphcore</div>
            <div className="mb-[0.5rem]">- Intel Startup Program</div>
            <div className="mb-[0.5rem]">- Telengana RevvUp</div>
            <div className="mb-[0.5rem]">- K-Tech Elevate</div>
            <div className="mb-[0.5rem]">- Nvidia Inception</div>
            <div className="mb-[0.5rem]">- AI Foundry by NASSCOM</div>
            


          </div>
        </div>
      </div>
    </div>
  );
}
