import React from "react";
import NeuropixelLogo from "../../Images/Neuropixel Logo.png";
import { useNavigate } from "react-router-dom";

export default function Terms() {
  const navigate = useNavigate();
  return (
    <div
      className="flex flex-col gap-[1vw] w-[100vw] h-screen"
      style={{ fontFamily: "poppins" }}
    >
      <div
        className="flex justify-between items-center px-[1vw] md:py-[1vw] py-[3vw] text-white"
        style={{
          background: "linear-gradient(90deg, #73BC65 0%, #0CB6D8 100%)",
        }}
      >
        <img
          src={NeuropixelLogo}
          alt="Neuropixel.AI"
          className="md:w-[15vw] w-[35vw] cursor-pointer"
          onClick={() => navigate("/")}
        />
      </div>

      <div className="flex flex-col gap-[1vw] justify-center items-center md:p-[1vw] p-[5vw]">
        <div className="w-full h-[20vw] md:h-[10vw] bg-[#f0f0f0] flex items-center justify-center rounded-md md:text-[2.2vw] text-[4vw] font-semibold text-black">
          Terms Of Use
        </div>
        <div className="flex flex-col  md:text-[0.9vw] font-[400] text-[2vw] md:p-[1vw] p-[4vw]">
          <ol
            className="md:pl-[0.5vw] md:mb-[1vw] mb-[3vw] font-semibold md:text-[1.2vw] text-[2.5vw] "
            style={{
              listStyleType: "decimal",
            }}
          >
            <li className="md:mb-[1vw] mb-[3vw]">
              <h1 className="font-semibold md:text-[1.2vw] text-[2.5vw] md:mb-[1vw] mb-[3vw]">
                GENERAL
              </h1>
              <ol
                className="list-lower-alpha list-inside pl-[0.5vw] md:mb-[1vw] mb-[3vw] md:text-[0.9vw] font-[400] text-[2vw]"
                style={{ listStyleType: "upper-roman" }}
              >
                <li className="md:mb-[1vw] mb-[3vw]">
                  This Terms of Use is an electronic record in terms of the
                  Information Technology Act, 2000 and rules thereunder as
                  applicable and amended from time to time. This Terms of Use
                  does not require any physical, electronic or digital
                  signatures.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  This Terms of Use is a legal agreement between you and
                  Neuropixel.AI Labs Private Limited, (“us”, “our”, “we”,
                  “Company”) with respect to your access and/ or use of our
                  website{" "}
                  <a
                    href="https://www.neuropixel.ai/"
                    className="text-blue-500 underline"
                  >
                    https://www.neuropixel.ai/
                  </a>{" "}
                  (hereinafter, “Platform”) and/ or our Services (defined below)
                  through the Company Application and/ or through any of various
                  mediums or devices now known or developed at a later date. The
                  Company and User (defined below) are hereinafter collectively
                  referred to as the “Parties” and individually as a “Party”.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  All the capitalised terms are defined in these Terms of Use.
                  For the purposes of this Terms of Use, the term,
                  <ol
                    className="pl-[2.2vw] md:mb-[1vw] mb-[3vw] mt-[1vw]"
                    style={{ listStyleType: "lower-alpha" }}
                  >
                    <li className="mb-[0.5vw]">
                      <strong>“Company Application(s)”</strong> shall mean and
                      include the software application(s) designed by the
                      Company (including the algorithms, codes, designs,
                      workflow) involving various interfaces to enable the Users
                      to use our Services and Solutions (including through
                      cloud-based access, direct delivery, etc.). It shall also
                      include all materials related to above mentioned software
                      application(s), and all customisations, modifications,
                      releases and updates thereto.
                    </li>
                    <li className="mb-[0.5vw]">
                      <strong>“Company Content”</strong> shall mean and include
                      material/ content in the form of text, images, video,
                      graphics, audio-visual works, and any other work or
                      material available through, contained in, displayed at,
                      communicated through, reproduced at or by the Platform and
                      the Company Application, that is owned or licensed to the
                      Company. It also includes the design, structure,
                      selection, coordination, expression, look, feel and
                      arrangement of the Platform and the Company Application.
                    </li>
                    <li className="mb-[0.5vw]">
                      <strong>“Force Majeure Event”</strong> shall mean any
                      event that is beyond the Company’s reasonable control and
                      shall include, without limitation, sabotage, fire, flood,
                      explosion, acts of God, pandemic, epidemic, civil
                      commotion, strikes or industrial action of any kind,
                      riots, insurrection, war, acts of government, computer
                      hacking, unauthorised access to computer, computer system
                      or computer network, computer crashes, breach of security
                      and encryption (provided beyond our reasonable commercial
                      control), power or electricity failure or unavailability
                      of adequate power or electricity
                    </li>
                    <li className="mb-[0.5vw]">
                      <strong>“User” or “you” or “your”</strong> shall refer to
                      natural person(s) (who are over 18 years) or legal persons
                      who access and/ or use our Platform and/ or our Services.
                    </li>
                    <li className="mb-[0.5vw]">
                      <strong>“User Content”</strong> shall mean all materials,
                      information, photography, writings and other content
                      provided by the User to the Company (including those
                      provided for use in the preparation of and/ or
                      incorporation in our work product, and/ or provision of
                      our Services).
                    </li>
                  </ol>
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  <strong>“What We Do:”</strong> We deploy recent advancements
                  in machine learning coupled with our proprietary algorithms
                  and workflows to provide cataloguing solutions and marketing
                  images for fashion brands, digital agencies, marketplaces and
                  photography studios. We, inter alia, (i) create and render
                  images of synthetic human models, which are customizable based
                  on various parameters; (ii) generate environments catering to
                  various moodboards of the customers, and (iii) adapt the
                  lighting of the output to reflect the input photograph’s
                  lighting conditions. Using the above, we have different
                  specific solutions to cater to a variety of use-cases - (i)
                  LIFT - creating editorial images from studio shot images, (ii)
                  MSWAP - swapping out the real model in a photograph for an AI
                  one, (iii) BSWAP - swapping out the background for another
                  after incorporating light conditions of the original, and (iv)
                  QUIN - swapping out a mannequin for an AI model (hereinafter
                  collectively referred to as the “Services”). These Solutions
                  or Services will be delivered or deliverable by us to you, via
                  the Company Applications serviced through the cloud (or via
                  other delivery medium or devices now known or developed at a
                  later date). The Solutions or Services available to you may
                  vary depending on the nature of your arrangement with us.
                  Further, we may, at our sole discretion, add, modify, or
                  remove any of the Services listed above, and/ or on our
                  Platform/ Company Application(s), from time to time, without
                  special notice to the Users.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  Access and use of the Platform and the use of our Services is
                  only authorised subject to your compliance with these Terms of
                  Use. If you do not agree to these Terms of Use, you should not
                  use our Platform and/ or Services. These Terms of Use shall
                  apply whenever you access or use the Platform and/ or our
                  Services.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  Your access to and/ or use of our Platform and/ or our
                  Services is, at all times, subject to these Terms of Use and
                  all applicable laws, rules, and regulations. Please read the
                  Terms of Use carefully. By accessing or using our Platform
                  and/ or our Services, you agree that you have read,
                  understood, and agree to be bound by the Terms of Use.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  Upon your consenting to the Terms of Use and any other
                  policies of the Company (which are incorporated by reference
                  into this Terms of Use), and other arrangements executed with
                  the Company, if any, the Company hereby grants you a limited,
                  revocable, non-assignable and non-exclusive licence to access
                  and/or use the Platform and/ or avail our Services, and the
                  Company will use commercially reasonable efforts to provide
                  you the Services.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  Any new features, tools, products or services which are added
                  to the Platform and/ or Company Application, and/ or the
                  subject matter of the Services shall also be subject to the
                  Terms of Use. You can review the most current version of the
                  Terms of Use at any time on the Platform. We reserve the right
                  to update, change or replace any part of these Terms of Use by
                  posting updates and/or changes. It is your responsibility to
                  check the Terms of Use periodically for changes. Your
                  continued use of or access to the Platform and/ or use of the
                  Services following the posting of any changes constitutes your
                  acceptance of those changes.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  Deviations from and/or additions to these Terms of Use are
                  only valid if agreed to explicitly by the Parties.
                </li>{" "}
              </ol>
            </li>

            <li className="md:mb-[1vw] mb-[3vw]">
              <h1 className="font-semibold md:text-[1.2vw] text-[2.5vw] md:mb-[1vw] mb-[3vw]">
                COMPANY RIGHTS
              </h1>
              <ol className="pl-[0.5vw] md:mb-[1vw] mb-[3vw] md:text-[0.9vw] font-[400] text-[2vw]">
                <li className="md:mb-[1vw] mb-[3vw]">
                  You understand, agree and acknowledge that the Company solely
                  and exclusively owns all rights, title and interest, including
                  rights in intellectual property such as trade mark rights,
                  copyright, patents, trade secrets, design rights (wherever
                  applicable) and any other rights whether recognised by law or
                  business practice or otherwise, whether vested, contingent or
                  future, and whether or not currently recognised in any
                  jurisdiction in the world (expressly including any and all
                  renewals, revivals, revisions and extensions rights thereof)
                  which subsist in or arise in relation to our business,
                  operations, technologies, algorithms, Company Content,
                  Platform, Company Application(s) and/or Services. The above is
                  with regard to all proprietary knowledge and workflows that
                  goes into the processing of the images.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  The input images and the output images you generate (after
                  utlising a credit) will be owned by the client.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  You also consent to the usage of your input images for
                  training purposes - like optimizing our segmentation
                  algorithm, fine-tuning our algorithm, optimizing our sort
                  order based on successful usage counts, etc. In all such cases
                  where your inputs are used, it will be anonymised to ensure
                  that client information is not tracked. In any circumstance,
                  this will not be shared anywhere externally.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  In the event that you, the customer, chooses to send us some
                  images for post-production editing, a masked copy of this
                  (without customer details) will be used internally for editing
                  purposes. Post edit, the file will be uploaded to your
                  dashboard and will be deleted from local storage
                  drives/devices within a period of 30 days.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  Nothing in these Terms of Use shall be construed to mean that
                  you or any third party have any right, title or interest
                  whatsoever in relation to the foregoing.
                </li>
              </ol>
            </li>
            <li className="md:mb-[1vw] mb-[3vw]">
              <h1 className="font-semibold md:text-[1.2vw] text-[2.5vw] md:mb-[1vw] mb-[3vw]">
                SYSTEM REQUIREMENTS
              </h1>
              <ol className="pl-[0.5vw] md:mb-[1vw] mb-[3vw] md:text-[0.9vw] font-[400] text-[2vw]">
                <li className="md:mb-[1vw] mb-[3vw]">
                  It is your responsibility to ensure your equipment (computer,
                  laptop, netbook, tablet or other mobile device) meets all the
                  necessary technical specifications and is compatible with our
                  Platform and/ or Company Application to enable your access and
                  use of the Platform and/ or our Services.
                </li>
              </ol>
            </li>

            <li className="md:mb-[1vw] mb-[3vw]">
              <h1 className="font-semibold md:text-[1.2vw] text-[2.5vw] md:mb-[1vw] mb-[3vw]">
                USER CONTENT
              </h1>
              <ol
                className="list-lower-alpha list-inside pl-[0.5vw] md:mb-[1vw] mb-[3vw] md:text-[0.9vw] font-[400] text-[2vw]"
                style={{ listStyleType: "upper-roman" }}
              >
                <li className="md:mb-[1vw] mb-[3vw]">
                  You are solely responsible for any and all User Content
                  displayed on, or transmitted via the Platform and/ or the
                  Company Application(s), and the Company disclaims all
                  liability and responsibility even if the Company enables them
                  with the uploading of such User Content onto the Platform and/
                  or the Company Application(s).
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  Users are not permitted to upload, transmit, distribute or
                  otherwise publish through the Platform and/ or the Company
                  Application(s), any content or comments which inter alia are,
                  and/or could be considered, to:
                  <ol
                    className="pl-[2.2vw] md:mb-[1vw] mb-[3vw] mt-[1vw]"
                    style={{ listStyleType: "lower-alpha" }}
                  >
                    <li className="mb-[0.5vw]">
                      be defamatory, obscene, pornographic, threatening,
                      invasive of privacy, infringing intellectual property
                      rights, proprietary rights, publicity rights or any other
                      rights;
                    </li>
                    <li className="mb-[0.5vw]">
                      be abusive, illegal or otherwise objectionable that would
                      constitute or encourage a criminal offence, violate the
                      rights of any party, or that would otherwise give rise to
                      liability or violate any law;
                    </li>
                    <li className="mb-[0.5vw]">
                      belong to another person and to which the User does not
                      have any right;
                    </li>
                    <li className="mb-[0.5vw]">
                      be grossly harmful, offensive, harassing, blasphemous,
                      paedophilic, hateful, or racially, ethnically
                      objectionable, disparaging, relating to or encouraging
                      money laundering or gambling, or otherwise unlawful in any
                      manner whatever;
                    </li>
                    <li className="mb-[0.5vw]">harm minors in any way;</li>
                    <li className="mb-[0.5vw]">
                      deceive or mislead regarding the origin of such messages;
                    </li>
                    <li className="mb-[0.5vw]">impersonate another person;</li>
                    <li className="mb-[0.5vw]">
                      contain software viruses or any other computer code, files
                      or programs designed to interrupt, destroy or limit the
                      functionality of any computer resource; and
                    </li>
                    <li className="mb-[0.5vw]">
                      threaten the unity, integrity, defence, security or
                      sovereignty of India, friendly relations with foreign
                      states, or public order or cause incitement to the
                      commission of any cognisable offence or prevents
                      investigation of any offence or is insulting any other
                      nation.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>

            <li className="md:mb-[1vw] mb-[3vw]">
              <h1 className="font-semibold md:text-[1.2vw] text-[2.5vw] md:mb-[1vw] mb-[3vw]">
                USE OF PLATFORM/ COMPANY APPLICATION
              </h1>
              <ol
                className="pl-[1.2vw] md:mb-[1vw] mb-[3vw] md:text-[0.9vw] font-[400] text-[2vw]"
                style={{ listStyleType: "upper-roman" }}
              >
                <li className="md:mb-[1vw] mb-[3vw]">
                  You agree to use the Platform and/ or the Company
                  Application(s) only: (a) for purposes permitted by the Terms
                  of Use; (b) in accordance with the applicable laws,
                  regulations or generally accepted practices or guidelines; and
                  (c) for availing the Services. You agree not to engage in
                  activities that may adversely affect the use of the Platform
                  and/ or the Company Application(s) by us or other users.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  You agree not to access (or attempt to access) the Platform
                  and/ or our Services by any means other than through the
                  interface that is provided by us. You shall not use any
                  deep-link, robot, spider or other automatic device, program,
                  algorithm or methodology, device, software, internet site,
                  web-based service, or other means to access, acquire, copy,
                  download, capture, reproduce, duplicate, archive, distribute,
                  upload, publish, modify, translate, broadcast, perform,
                  display, sell, transmit or retransmit any Company Content or
                  anything subject to our rights or create any derivative work
                  and/or content based on any content or rights which is not
                  owned by you or interfere with, or circumvent any right
                  associated with the Company or monitor any portion of the
                  Platform or Company Application(s), or in any way reproduce or
                  circumvent the navigational structure or presentation of the
                  Platform or and/ or the Company Application(s), obtain or
                  attempt to obtain any materials, documents or information
                  through any means not specifically made available through the
                  Platform and/ or the Company Application(s).
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  You agree that your use of the Platform and/ or the Company
                  Application(s) may give you the opportunity to view, publish,
                  transmit, submit post/queries and/or comment in accordance
                  with these Terms of Use. You undertake that you shall not
                  misuse the content that you access and view, including without
                  limitation, download/store the content by
                  illegal/non-permitted means, or infringe any of Company’s/ its
                  licensors’ or other users’ intellectual property.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  You understand and agree that in case the Platform and/ or the
                  Company Application(s) allows you to download or stream any of
                  the content not owned by you, you will not use, sell, transfer
                  or transmit the content to any platform where you explicitly
                  do not further own the rights to the content uploaded. You
                  agree not to use or launch any automated system, including
                  without limitation, "robots," "spiders," "offline readers,"
                  etc. or "load testers" such as wget, apache bench,
                  mswebstress, httpload, blitz, Xcode Automator, Android Monkey,
                  etc., that accesses the Platform and/ or the Company
                  Application(s) in a manner that sends more request messages to
                  our servers in a given period of time than a human can
                  reasonably produce in the same period by using the Platform.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  You must secure our permission before you measure, test,
                  health check or otherwise monitor any network equipment,
                  servers or assets hosted on our domain. You agree not to use
                  the Platform and/ or the Company Application(s) for any
                  commercial solicitation or spam purposes. You agree not to
                  spam, or solicit for commercial purposes, any other users of
                  the Platform.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  You will not, nor allow third parties on your behalf to: (i)
                  resell or charge others for use of or access to the Platform
                  or any Company Content or our Services; (ii) duplicate, alter,
                  modify, disassemble, decompile, copy, distribute, transfer,
                  exchange or translate the Company Content or any part thereof,
                  in any medium, or create derivative works of the Company
                  Content, of any kind whatsoever or attempt to reverse
                  engineer, alter or modify any part of the Company Content.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  You shall, at all times, comply with applicable laws at the
                  time of using the Platform and /or our Services. You will also
                  ensure that all the employees, representatives and other users
                  of the Platform and/ or our Services, accessing from your
                  licenses with the Company, also adhere to these Terms of Use
                  and the obligations mentioned herein.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  You understand that the Platform and/ or the Company
                  Application(s) may contain third-party content submitted to us
                  by other users or third parties. We expressly disclaim any
                  liability arising out of the content contributed by such third
                  parties/ users and/or usage of products or services provided
                  by such users/ third parties. The Platform and/ or the Company
                  Application(s) may contain articles contributed by several
                  users and the views expressed are exclusively their own and do
                  not represent the views of the Company, its affiliates and/or
                  its management. If the Platform and/ or the Company
                  Application(s) provides links to other websites or
                  destinations, you should not infer or assume that we operate
                  and/ or control it. In such cases, you will be deemed governed
                  by the terms of use and privacy policies of such third-party
                  platforms. You agree and understand that the Company assumes
                  no responsibilities for content on such third-party websites.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  We may provide you with access to third-party tools which we
                  neither monitor nor over which we have any control or input.
                  You acknowledge and agree that we provide access to such tools
                  'as is' and 'as available' without any warranties,
                  representations or conditions of any kind and without any
                  endorsement. We shall have no liability whatsoever arising
                  from or relating to your use of such third-party tools. Any
                  use by you of such third-party tools offered through the
                  Platform and/ or the Company Application(s) is entirely at
                  your own risk and discretion; and you should ensure that you
                  are familiar with and approve of the terms on which tools are
                  provided by the relevant third-party provider(s).
                </li>
              </ol>
            </li>

            <li className="md:mb-[1vw] mb-[3vw]">
              <h1 className="font-semibold md:text-[1.2vw] text-[2.5vw] md:mb-[1vw] mb-[3vw]">
                INDEMNIFICATION & DISCLAIMERS
              </h1>
              <ol
                className="pl-[1.2vw] md:mb-[1vw] mb-[3vw] md:text-[0.9vw] font-[400] text-[2vw]"
                style={{ listStyleType: "upper-roman" }}
              >
                <li className="md:mb-[1vw] mb-[3vw]">
                  The User explicitly agrees to fully indemnify and hold
                  harmless the Company and its employees, directors, owners,
                  associates, licensors and affiliates from and against any and
                  all actions, claims, charges, costs, demands, damages, losses,
                  expenses, suits, proceedings, and liabilities of whatever
                  nature and howsoever arising (including, but not limited to
                  any legal or other professional fees and the costs of
                  defending or prosecuting any claim and any loss of profit,
                  goodwill, and any other direct or consequential loss, whether
                  or not pecuniary), incurred or suffered by any or all of them,
                  directly or indirectly, by reason of: (i) any act of
                  commission or omission which the User commits directly or
                  indirectly in relation to the Platform or the Company
                  Application(s) or Company Content; (ii) any breach by the User
                  of its obligations under these Terms of Use or any other
                  policies of the Company; (iii) violation of the rights of a
                  third party by the User, including but not limited to
                  infringement of any intellectual property, proprietary rights
                  or right to privacy; (iv) violation of any applicable law by
                  the User. The provisions of this indemnification clause shall
                  survive the termination of this Terms of Use.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  We do not guarantee, represent or warrant in any manner that
                  your use of our Platform and/ or Services will be
                  uninterrupted, timely, secure or error-free, or that the
                  results that may be obtained from the use of the Platform and/
                  or Services will be accurate or reliable or effective in
                  nature. You understand and accept that the reliance on the
                  Platform and/ or Services is solely at your own judgement and
                  risk. You should take all responsibility for your own actions
                  in utilising the Platform and/ or Services, and we shall not
                  be liable for any such action or consequence thereof.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  You acknowledge that the Platform and/ or Company
                  Application(s) is made available on an “as-is” basis with no
                  representations being made as to accuracy, impartiality or
                  with no representation that the Platform and/ or our Services
                  will be error free or uninterrupted in nature. Your access to
                  and reliance/use of the Platform and /or Services is at your
                  own risk. The Company explicitly disclaims all warranties,
                  express or implied, (whether direct or indirect, or pecuniary
                  or otherwise) including, without limitation the implied
                  warranties of merchantability, completeness and fitness for a
                  particular purpose, for any errors or omissions therein, and
                  for any results which may be/are obtained/obtainable in
                  relation to the use of the Platform and/ or our Services,
                  including non-infringement with respect to any Company
                  Content. The User agrees to such disclaimer of liability, and
                  agrees that it is reasonable.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  You understand that when using the Platform and/ or our
                  Services, you may be exposed to content from a variety of
                  sources, and that the Company is not responsible for the
                  accuracy, usefulness, safety or intellectual property rights
                  of, or relating to, such content on the Platform and/ or
                  Company Application(s). The Company may, but is not obligated
                  to, monitor or control the content. Any use or reliance on any
                  content is at your own risk.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  Each User explicitly agrees that under no circumstances shall
                  the Company and/or its employees, directors, owners,
                  associates, licensors and/or affiliates be liable for any
                  claims, charges, demands, damages, liabilities, losses, and
                  expenses of whatever nature and howsoever arising, including,
                  without limitation any compensatory, incidental, direct,
                  indirect, special, punitive, or consequential damages, loss of
                  use, loss of data, loss caused by a computer or electronic
                  virus, loss of income or profit, loss of or damage to
                  property, claims of third parties, or other losses of any kind
                  or character, even if any/all of them has/have been advised of
                  the possibility of such damages or losses, arising out of or
                  in connection with the use of the Platform and/ or the
                  Services, any third-party products and services viewable at/
                  through Platform and/ or Company Application(s), or any third
                  party site including any linked site or referred site.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  The Company and its employees, directors, owners, associates,
                  licensors and/or affiliates do not warrant that the Platform,
                  Company Application(s), all third-party sites and/or the
                  server(s) from which it/they is/are run is/are free of viruses
                  or other potentially harmful codes or that the operation of
                  Platform or Company Application(s) or any third-party site
                  will be uninterrupted or error-free or that defects will be
                  corrected. Users assume total responsibility for establishing,
                  inter alia, procedures for data back up and virus checking,
                  and the Company shall not bear any responsibility for any
                  interruption of Service or other problems or losses caused by
                  or in relation to the running of the Platform and/ or Company
                  Application(s). No advice, course of conduct or information,
                  whether oral or written, obtained by you from the Company or
                  any party or through the Platform and/ or Company
                  Application(s) shall create any warranty or liability onto the
                  Company.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  You agree that we shall not be under any liability, whatsoever
                  to you in the event of non-availability of the Services/
                  Platform or any portion thereof occasioned by any Force
                  Majeure Events, or any other cause whatsoever beyond the
                  reasonable control of Company.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  Further, all the synthetic models generated as part of our
                  Services are purely technological renditions, and do not have
                  any bearing or resemblance in name or likeness to any persons
                  living or dead. Any such cases are a coincidence and not
                  intended. We disclaim any and all liability or claims on this
                  account. To be extra cautious, you may cross verify the
                  outputs using facial recognition software (such as PimEye) if
                  you require.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  Subject to the aforementioned clauses, the indemnification
                  obligations of the Company, its subsidiaries, affiliates, and
                  their licensors, service providers, employees, agents,
                  officers, and directors, to any party (regardless of the form
                  of action, whether in contract, tort, or otherwise) for any
                  claims by the User on account of any losses, liabilities,
                  damages or expenses shall be limited to the service fee/
                  payment made by the User to the Company in the 1 (one) month
                  preceding the event giving rise to any such liability, and in
                  any event will need be claimed by the User during the term of
                  the arrangement with the Company. In the event a User uses the
                  Platform/Services without being required or obligated to pay
                  the Company any monetary consideration or fees for availing
                  such Services, the collective liability of the Company, its
                  subsidiaries, affiliates, and their licensors, service
                  providers, employees, agents, officers, and directors, to any
                  party (regardless of the form of action, whether in contract,
                  tort, or otherwise), will not exceed INR 500 (Indian Rupees
                  Five Hundred).
                </li>
              </ol>
            </li>

            <li className="md:mb-[1vw] mb-[3vw]">
              <h1 className="font-semibold md:text-[1.2vw] text-[2.5vw] md:mb-[1vw] mb-[3vw]">
                REPRESENTATIONS AND WARRANTIES
              </h1>
              <div className="md:text-[0.9vw] font-[400] text-[2vw]">
                You represent and warrant that:
              </div>
              <ol
                className="pl-[2.2vw] md:mb-[1vw] mb-[3vw] md:text-[0.9vw] font-[400] text-[2vw]"
                style={{ listStyleType: "upper-roman" }}
              >
                <li className="md:mb-[1vw] mb-[3vw]">
                  You are a person of legal age to form a binding contract, in
                  accordance with the applicable laws of the territory/ country
                  from where you are accessing/ using the Platform or our
                  Services.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  In case you are accessing/ using the Platform/ Services as a
                  representative of and on behalf of any organisation or legal
                  entity, you hereby represent and warrant that (i) you have the
                  full authority to represent such legal entity and have the
                  power to bind them to these Terms of Use; and (ii) you
                  understand that the Terms of Use, upon acceptance by you, is
                  applicable to and binds all other persons accessing and/ or
                  using the Platform and/ or the Services on behalf of the same
                  legal entity or organisation for this purpose. In such cases,
                  the references to “User”, “you” and “your” in these Terms,
                  shall mean such organization or entity on whose behalf you
                  have consented to the Terms of Use.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  You are the exclusive owner or the licenced user of the User
                  Content, and you have the right to enter into this Terms of
                  Use; and you own or retain the necessary licenses, rights,
                  consents, and permissions to use and authorize us to use the
                  User Content.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  Content attributable to you does not violate any rights,
                  including intellectual property of any third party; and
                  complies with the provisions of all applicable laws. In
                  particular, you have the written consent, release, and/or
                  permission of each and every identifiable individual person in
                  the User Content to use the name or likeness of each and every
                  such identifiable individual person to enable inclusion and
                  use of the User Content in the manner contemplated by these
                  Terms of Use and the arrangement between the Parties.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  You have not granted any rights to any third party whatsoever,
                  or entered into any agreement, which could or would conflict
                  with the Company’s rights under these Terms of Use.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  There are no claims, actions or proceedings, pending or
                  threatened, affecting the User Content or which could impede
                  the Company from enjoying any of the rights granted to it
                  under these Terms of Use.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  These representations and warranties shall survive the
                  termination of this Terms of Use.
                </li>
              </ol>
            </li>

            <li className="md:mb-[1vw] mb-[3vw]">
              <h1 className="font-semibold md:text-[1.2vw] text-[2.5vw] md:mb-[1vw] mb-[3vw]">
                TERMINATION
              </h1>
              <ol
                className="pl-[1.2vw] md:mb-[1vw] mb-[3vw] md:text-[0.9vw] font-[400] text-[2vw]"
                style={{ listStyleType: "upper-roman" }}
              >
                <li className="md:mb-[1vw] mb-[3vw]">
                  The Terms of Use will continue to apply until terminated
                  either by you or us as set forth below. If you want to
                  terminate the application of these Terms of Use, you may do so
                  by not accessing/ using, or stopping to access/ use our
                  Platform/ Services.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  You hereby irrevocably understand that we reserve the right at
                  our sole and exclusive discretion to deny/ cease your access
                  to the Platform or Services, temporarily or permanently
                  without liability or notice if:
                  <ol
                    className="pl-[2.2vw] md:mb-[1vw] mb-[3vw] mt-[1vw]"
                    style={{ listStyleType: "lower-alpha" }}
                  >
                    <li className="mb-[0.5vw]">
                      You access or use of Platform/ Services in an unauthorized
                      manner;
                    </li>
                    <li className="mb-[0.5vw]">
                      You breach any of the provisions of the Terms of Use, the
                      privacy policy or any other terms, conditions, agreements
                      or policies that may be applicable to you from time to
                      time (or have acted in a manner that clearly shows that
                      you do not intend to, or are unable to, comply with the
                      same);
                    </li>
                    <li className="mb-[0.5vw]">
                      You violate any applicable laws, or wilfully compromise
                      any other user’s privacy, or have acted unethically;
                    </li>
                    <li className="mb-[0.5vw]">
                      You have infringed, breached, violated, any third – party
                      intellectual property rights of any third party and which
                      involves the Platform, the Company Application and/ or the
                      Services;
                    </li>
                    <li className="mb-[0.5vw]">
                      We are required to do so by law (for example, where the
                      provision of the Services to you is, or becomes, unlawful
                      or upon receiving request for termination by law
                      enforcement or other government agencies);
                    </li>
                    <li className="mb-[0.5vw]">
                      The provision of the Services to you by us is, in our
                      opinion, no longer commercially viable or in any way
                      detrimental to us or our business; or
                    </li>
                    <li className="mb-[0.5vw]">
                      We have elected to discontinue, with or without reason,
                      your access to the Platform, Services, or any part
                      thereof.
                    </li>
                  </ol>
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  We may also terminate or suspend all or a portion of access to
                  the Platform/Services without reason, and it may include: (a)
                  removal of access to use the Platform or for availing the
                  Services; and (b) deletion of your records, files and
                  materials (or any part thereof) that may be available with us.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  Any such termination or restriction in access to the Platform/
                  Services shall be without prejudice to other rights of the
                  Company herein or under applicable law (including obtaining
                  any injunctive or equitable relief that we deem necessary or
                  appropriate in such circumstances). Upon such termination, the
                  Company shall have no liability towards such User and no
                  further obligations under these Terms of Use.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  Your obligations and liabilities incurred prior to the
                  termination date (including any payment obligations) shall
                  survive the termination of this Terms of Use for all purposes.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  Upon termination of this Terms of Use, you shall immediately
                  cease and desist from using or accessing the Platform and/or
                  Services.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  Further, upon termination of this Terms of Use, we may delete
                  any content or other materials relating to your use of the
                  Platform and/ or the Services and we will have no liability to
                  you or any third party for doing so.
                </li>
              </ol>
            </li>

            <li className="md:mb-[1vw] mb-[3vw]">
              <h1 className="font-semibold md:text-[1.2vw] text-[2.5vw] md:mb-[1vw] mb-[3vw]">
                COMMUNICATIONS
              </h1>
              <ol
                className="pl-[1.2vw] md:mb-[1vw] mb-[3vw] md:text-[0.9vw] font-[400] text-[2vw]"
                style={{ listStyleType: "upper-roman" }}
              >
                <li className="md:mb-[1vw] mb-[3vw]">
                  When you use the Platform or Services or send any User Content
                  or communication to us, you agree and understand that you are
                  communicating with us through electronic records, and you also
                  consent to receiving communications via electronic records
                  from us periodically and as and when required.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  We will communicate with you by email and/or via the Platform
                  and/ or Company Application(s), and/or mobile number available
                  with us. You are responsible for providing and maintaining
                  current email, mobile phone, address (current/ temporary and
                  permanent), and other account information with us. You further
                  warrant that information provided to us shall be correct and
                  are bound to inform to us promptly, if any change occurs in
                  the information provided earlier.
                </li>
              </ol>
            </li>

            <li className="md:mb-[1vw] mb-[3vw]">
              <h1 className="font-semibold md:text-[1.2vw] text-[2.5vw] md:mb-[1vw] mb-[3vw]">
                OTHER TERMS
              </h1>
              <ol
                className="pl-[1.2vw] md:mb-[1vw] mb-[3vw] md:text-[0.9vw] font-[400] text-[2vw]"
                style={{ listStyleType: "upper-roman" }}
              >
                <li className="md:mb-[1vw] mb-[3vw]">
                  Confidentiality: The Company shall maintain confidentiality of
                  the User’s customer data, orders, transactions, payment terms,
                  etc. Similarly, the User shall maintain confidentiality of the
                  Company’s business information, payment terms, the Company
                  Applications and other Platform/ Services related data and
                  details. A Party may disclose confidential information where
                  compelled to do so, by any government, judicial or
                  quasi-judicial authority, under prior intimation to the other
                  Party. The Company shall be entitled to undertake necessary
                  disclosure to its investors, advisors, consultants and such
                  other persons on a need-to-know basis under confidentiality/
                  non-disclosure arrangements with such parties. The
                  confidentiality obligations shall be further detailed in the
                  privacy policy of the Company, which shall be applicable to
                  the arrangement between you and the Company.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  Data & its protection: You agree that we may collect and use
                  technical data and related information, including but not
                  limited to technical information about your device, system and
                  application software, and peripherals, that is gathered
                  periodically, to facilitate the provision of updates, product
                  support, and other Services to you (if any). We may use this
                  information as per the privacy policy of the Company,
                  including to improve our products or Services or technologies.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  The Company’s primary goal in collecting the information is
                  for the Company to render the Services to you, to ensure
                  quality and efficiency in the Services provided to you, and to
                  provide efficient user interface to you. The Company will be
                  using the information only for our internal operational
                  purposes, such as providing, maintaining, evaluating, and
                  improving the Platform and the Services, and also for
                  providing support. The data collection and obligations shall
                  be further detailed in the privacy policy of the Company,
                  which shall be applicable to the arrangement between you and
                  the Company.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  Please note that the Company does not trade or sell any
                  personally identifiable information of the Users or their
                  customers in any manner.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  Sharing of data: The Company may share information in
                  accordance with its privacy policy, including to third parties
                  who support our business, such as providing technical
                  infrastructure services, analysing how our Services are used,
                  measuring the effectiveness of advertisements, providing
                  customer support and business support services, facilitating
                  payments, or conducting research and surveys, and such
                  information will be used for the limited purposes of such
                  services received by us. Any sharing of information with such
                  third parties will be subject to appropriate confidentiality
                  and non-disclosure agreements.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  The Company takes standard precautions including technical
                  measures to safeguard your information against loss, misuse or
                  theft, as well as against destruction, alteration, disclosure
                  or unauthorized access. Specifically, while the Company will
                  ensure its efforts to protect the User’s information in line
                  with commercially reasonable efforts, however, the Company
                  does not represent, warrant, or guarantee that the information
                  will be protected against unauthorized access, loss, misuse,
                  or alterations beyond the reasonable control of the Company.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  Notwithstanding anything contained in this Terms or Use or the
                  privacy policy or elsewhere, the Company shall not be held
                  responsible for any loss, damage or misuse of your
                  information, if such loss, damage or misuse is attributable to
                  a Force Majeure Event.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  All Users who choose to access the Platform and/ or our
                  Services, acknowledge and agree that they do so exclusively at
                  their own risk, of their own volition, and agree that they are
                  responsible for compliance with any and all laws applicable to
                  them in relation to their use of / access to Platform and/ or
                  the Services. Any actions, legal proceedings, etc., to
                  safeguard the rights granted or subsisting or recognised under
                  these Terms of Use may be initiated or taken by the Company at
                  its own cost and discretion, and the User agrees to cooperate
                  with the Company in this regard.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  Assignment: It is agreed that the Company is entitled to
                  assign, licence or sub-licence to any party or person either
                  wholly or partially its benefits/rights and/or obligations
                  under these Terms of Use, and/or to assign these Terms of Use
                  itself. The User is not entitled to transfer/assign his own
                  obligations herein to any other party at any time, and/or to
                  assign these Terms of Use itself without the consent of the
                  Company. Subject to these limitations, these Terms of Use will
                  insure to the benefit of and be binding upon the Parties,
                  their successors, heirs, administrators and permitted
                  assignees, as the case may be.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  Waiver: Failure by the Company to insist upon strict
                  performance of any of the terms and conditions herein, or
                  delay in exercising any of its remedies, shall not constitute
                  a waiver of such terms and conditions, or a waiver of any
                  default, or a waiver of the right to any remedy.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  Severability: If any term, condition, or provision in the
                  Terms of Use is found to be invalid, unlawful or unenforceable
                  to any extent, it will be severed from the Terms of Use, and
                  the remainder of the Terms of Use will then continue to be
                  valid and enforceable to the fullest extent permitted by law,
                  unaffected by the severance.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  Force Majeure: Neither Party shall be liable to the other
                  Party or to any third party for any failure or delay on its
                  part in performing any of its obligations under this Terms of
                  Use where such failure or delay is caused by an event of Force
                  Majeure Event. Nothing herein will affect any payment
                  obligations of a Party, which shall not stand affected by a
                  Force Majeure Event.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  Survival: The Parties hereto agree and confirm that the
                  provisions relating to grant of rights to the Company
                  (including right to payment), undertakings and obligations of
                  the Users, representations and warranties, indemnification and
                  disclaimers, arbitration, governing law and dispute
                  resolution, shall survive the termination of these terms of
                  use.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  Governing Law and Dispute Resolution: These Terms of Use shall
                  be governed exclusively by the laws of India, notwithstanding
                  the conflict of law principles. Subject to the provisions in
                  relation to arbitration in these Terms of Use, all matters,
                  claims and disputes arising under and in respect of these
                  Terms of Use shall be subject to the exclusive jurisdiction of
                  competent courts in Bangalore to which the Parties irrevocably
                  submit themselves. In the event of any question, dispute or
                  difference whatsoever arising between the Parties to these
                  Terms of Use out of or relating to the construction, meaning,
                  scope, operation or effect of these Terms of Use or the
                  validity or the breach thereof, it shall be referred to a sole
                  arbitrator to be appointed by the Company, provided that the
                  arbitrator is not financially interested with the Company or
                  its directors, other than towards payment of the arbitration
                  fees. The provisions of the Arbitration and Conciliation Act,
                  1996, will be applicable and the award made and substantiated
                  in writing thereunder shall be final and binding upon the
                  Parties hereto, subject to legal remedies available under the
                  law. The arbitration proceedings will be conducted in
                  Bangalore in English, and governed exclusively in all respects
                  by the law of India.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  Brand usage: The Company shall be entitled to use the brand
                  name, logo and image of the Users for the limited purposes of
                  business development and investor information, and this shall
                  include appropriate referencing on the Company’s websites,
                  brochures and marketing materials.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  Notices: Any notices, requests, demands or other communication
                  required or permitted to be given under this arrangement shall
                  be written in English and shall be delivered by email or
                  internationally recognised courier service and properly
                  addressed to the designated representative of each Party. Any
                  notice, request, demand or other communication delivered to
                  the Party to whom it is addressed as provided in this section
                  shall be deemed to have been given and received, (i) if sent
                  by internationally recognised courier service on the date on
                  which it is received by the other party, as acknowledged by
                  the courier service; and (ii) if sent by e-mail, the day
                  immediately after the date of transmission of the e-mail.
                </li>
              </ol>
            </li>

            <li className="md:mb-[1vw] mb-[3vw]">
              <h1 className="font-semibold md:text-[1.2vw] text-[2.5vw] md:mb-[1vw] mb-[3vw]">
                CONTACT US
              </h1>
              <ol className="pl-[0.5vw] md:mb-[1vw] mb-[3vw] md:text-[0.9vw] font-[400] text-[2vw]">
                <li className="md:mb-[1vw] mb-[3vw]">
                  If you have any questions, complaints or claims with respect
                  to the Platform or our Services, please direct your
                  correspondence to the address as given below.
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  Email: arvind@neuropixel.ai
                </li>
                <li className="md:mb-[1vw] mb-[3vw]">
                  We shall respond to and address all reasonable concerns or
                  inquiries in a reasonable amount of time.
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
}
